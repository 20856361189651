<script setup>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import SetupPasswordForm from '@/components/globalSettings/securitySettings/SetupPasswordForm.vue'
import ChangePasswordForm from '@/components/globalSettings/securitySettings/ChangePasswordForm.vue'
import TwoFactorAForm from '@/components/globalSettings/securitySettings/TwoFactorAForm.vue'

const store = useStore()
const showGlobalSettings = computed(() => store.getters['showGlobalSettings'])
const sessionsList = computed(() => store.getters['auth/sessionsList'])
const sessionsLoading = computed(() => store.getters['auth/sessionsLoading'])
const isPasswordSet = computed(() => store.getters['auth/isPasswordSet'])

const deleteSession = (sessionId) => {
  store.dispatch('auth/deleteUserSessionById', sessionId).then(()=>{
    success()
  }).catch(e=> {
    error(e.message)
  })
}

onMounted(()=>{
  store.dispatch('auth/getUserSessions')
})

watch(showGlobalSettings, (value)=>{
  value && store.dispatch('auth/getUserSessions')
})

</script>

<template>
  <div>
    <a-typography-title :level="5">
      {{ $t('components.securitySettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-typography-paragraph strong>
      {{ $t('components.securitySettings.passwordSettings') }}
    </a-typography-paragraph>
    <SetupPasswordForm :show-form="!isPasswordSet" />
    <ChangePasswordForm :show-form="isPasswordSet" />
    <a-divider />
    <TwoFactorAForm />
    <a-divider />
    <a-typography-paragraph strong>
      {{ $t('components.securitySettings.activeSessions') }}
    </a-typography-paragraph>
    <a-list
      class="sessions-list"
      :loading="sessionsLoading"
    >
      <a-list-item
        v-for="session in sessionsList"
        :key="session.sessionId"
      >
        <a-list-item-meta>
          <template #title>
            {{ session.ip }} <a-typography-text type="secondary">
              ({{ session.country }})
            </a-typography-text>
            <a-tag
              v-if="session.isCurrent"
              style="margin-left: 4px;"
              color="var(--ant-primary-color)"
            >
              {{ $t('components.securitySettings.currentSession') }}
            </a-tag>
          </template>
        </a-list-item-meta>
        <a-space>
          <div class="log-date">
            <a-typography-text>
              <span v-format-date="{date: session.createdAt, format: 'MMM D, HH:mm'}" />
            </a-typography-text>
          </div>

          <a-button
            v-if="!session.isCurrent"

            size="small"
            type="default"
            @click="deleteSession(session.sessionId)"
          >
            {{ $t('components.securitySettings.logOut') }}
          </a-button>
        </a-space>
      </a-list-item>
    </a-list>
  </div>
</template>

<style scoped lang="less">

</style>
