<script setup>
import { defineComponent } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { FunFactsSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: FunFactsSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const { t } = useI18n()
const slide = props.slideObj || new FunFactsSlide()

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

const updateSlide = () => {
  emit('update-slide', slide)
}

if (!slide.widgetId) {
  updateSlide()
}
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.funFacts.slideStep1.subTitle')"
    :title="$t('components.slides.funFacts.slideStep1.title')"
    no-border
  />
</template>


<style lang="less">

</style>
