<script setup>
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { TEDTalksSlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { error, fancyTimeFormat, formatDate } from '@/utils'
import ThumbnailsItem from '@/components/ThumbnailsItem.vue'
import ThumbnailsList from '@/components/ThumbnailsList.vue'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: TEDTalksSlide,
  groupId: String,
  zoning: Boolean,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const { t } = useI18n()
const store = useStore()
const slide = props.slideObj || new TEDTalksSlide()
const { muted, objectFit } = slide.slideData
const isMuted = props.zoning || muted
const continueVideoOnRestarts = ref(!!slide.slideData.continueVideoOnRestarts)
const tedTalksTopics = computed(() => store.getters['slides/tedTalksTopics'] || [])
const tedTalksSpeakers = computed(() => store.getters['slides/tedTalksSpeakers'] || [])
const tedTalks = ref([])
const tedTalksState = reactive({
  topic: null,
  speaker: null
})
const tedTalksLoading = ref(false)

const tedTalksTopicsOptions = computed(() => tedTalksTopics.value?.map(({ slug }) => ({
  value: slug,
  label: slug
})) || [])

const tedTalksSpeakersOptions = computed(() => tedTalksSpeakers.value?.map(({slug, full_name}) => ({
  value: slug,
  label: full_name
})) || [])

const selectedTedTalk = ref(slide.tedTalk || null)
!selectedTedTalk.value && emit('update:disabled', true)


onMounted(()=>{
  store.dispatch('slides/getTedTalksTopics')
  store.dispatch('slides/getTedTalksSpeakers')
})

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

const updateSlide = () => {
  emit('update-slide', slide)
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const updateSlideData = () => {
  const tedTalkId = selectedTedTalk.value?.id
  selectedTedTalk.value?.duration_in_seconds && slide.setDuration(selectedTedTalk.value.duration_in_seconds)
  slide.updateSlideData({
    tedTalkId,
    muted: isMuted,
    objectFit,
    continueVideoOnRestarts: continueVideoOnRestarts.value
  })
}

if (!slide.widgetId) {
  toggleDisabled(true)
}

const searchTedTalks = async () => {
  if (!tedTalksState.topic && !tedTalksState.speaker) {
    tedTalks.value = []
    return
  }
  tedTalksLoading.value = true
  try {
    tedTalks.value = await store.dispatch('slides/searchTedTalks', { topic: tedTalksState.topic, speaker: tedTalksState.speaker})
  }
  catch (e) {
    error(e.message)
  }
  tedTalksLoading.value = false
}

const filterOption = (input, option) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const handleSpeakerChange  = () => {
  searchTedTalks()
}

const handleTopicChange  = () => {
  searchTedTalks()
}

const handleTedTalkSelect = (tedTalk) => {
  selectTedTalk(tedTalk)
}

const selectTedTalk = (tedTalk) => {
  selectedTedTalk.value = tedTalk || null
  if (!selectedTedTalk.value?.id) {
    return toggleDisabled(true)
  }
  updateSlideData()
  updateSlide()
  toggleDisabled(false)
}

watch(() => continueVideoOnRestarts.value, () => {
  updateSlideData()
  updateSlide()
})
</script>

<template>
  <DurationSlideStep
    :key="slide.duration"
    :slide="slide"
    @update-slide="updateSlide"
  />
  <template v-if="!selectedTedTalk">
    <SlideStep
      :sub-title="$t('components.slides.tedTalks.slideStep1.subTitle')"
      :title="$t('components.slides.tedTalks.slideStep1.title')"
    >
      <a-select
        v-model:value="tedTalksState.topic"
        style="width: 100%; margin-bottom: 16px;"
        show-search
        :options="tedTalksTopicsOptions"
        allow-clear
        :filter-option="filterOption"
        placeholder="Select Topic"
        @change="handleTopicChange"
      />

      <a-select
        v-model:value="tedTalksState.speaker"
        style="width: 100%"
        show-search
        :options="tedTalksSpeakersOptions"
        allow-clear
        :filter-option="filterOption"
        placeholder="Select Speaker"
        @change="handleSpeakerChange"
      />
    </SlideStep>
    <SlideStep
      :sub-title="$t('components.slides.tedTalks.slideStep2.subTitle')"
      :title="$t('components.slides.tedTalks.slideStep2.title')"
      :disabled="!tedTalks?.length"
    >
      <a-spin :spinning="tedTalksLoading">
        <ThumbnailsList>
          <ThumbnailsItem
            v-for="tedTalk in tedTalks"
            :key="tedTalk.id"
            :thumbnail="tedTalk?.thumbnail_url"
            :name="tedTalk.title"
            :full-name="tedTalk.title"
            :selectable="true"
            @on-select="() => handleTedTalkSelect(tedTalk)"
          />
        </ThumbnailsList>
      </a-spin>
    </SlideStep>
  </template>
  <template v-else>
    <SlideStep
      :sub-title="selectedTedTalk.name"
      :title="$t('components.slides.tedTalks.slideStep3.title')"
    >
      <template #extra>
        <a-button @click="handleTedTalkSelect()">
          <template #icon>
            <SyncOutlined />
          </template>
          {{ $t('components.slides.tedTalks.slideStep3.replaceButtonsText') }}
        </a-button>
      </template>
      <div class="ted-talk-card">
        <div class="ted-talk-thumbnail">
          <img
            v-if="selectedTedTalk.thumbnail_url"
            :src="selectedTedTalk.thumbnail_url"
          >
          <SyncOutlined
            v-else
            spin
          />
        </div>
        <div class="ted-talk-desc">
          <a-typography-text>
            {{ $t('components.slides.tedTalks.slideStep3.tedTalkTitle') }} {{ selectedTedTalk.title }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{ $t('components.slides.tedTalks.slideStep3.tedTalkEvent') }} {{ selectedTedTalk.event }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{ $t('components.slides.tedTalks.slideStep3.tedTalkDuration') }} {{ fancyTimeFormat(selectedTedTalk.duration_in_seconds) }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{ $t('components.slides.tedTalks.slideStep3.tedTalkPublished') }} {{ formatDate(selectedTedTalk.publish_date) }}
          </a-typography-text>
        </div>
      </div>
    </SlideStep>
  </template>
  <SlideStep
    :title="$t('components.slides.tedTalks.slideStep4.title')"
    no-border
  >
    <a-radio-group v-model:value="continueVideoOnRestarts">
      <div style="margin-bottom: 8px;">
        <a-radio
          :value="true"
        >
          {{ $t('components.slides.tedTalks.slideStep4.continuePlayback') }}
        </a-radio>
      </div>
      <div>
        <a-radio
          :value="false"
        >
          {{ $t('components.slides.tedTalks.slideStep4.playNewVideo') }}
        </a-radio>
      </div>
    </a-radio-group>
  </SlideStep>
</template>

<style lang="less" scoped>
.ted-talk-card {
  margin-bottom: 16px;
  overflow: auto;
  display: flex;

  .ted-talk-thumbnail {
    width: 384px;
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 216px;
      float: left;
      margin-right: 32px;
      border-radius: 4px;
    }
  }
}

</style>
