<script setup>
import { computed, ref, toRaw } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { QuotesSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: QuotesSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const slide = props.slideObj || new QuotesSlide()
const { t } = useI18n()
const { categories: categoriesList } = slide.slideData
const checkedList = ref(categoriesList)
const categoriesOptions = ref([
  {
    label: t('components.slides.quotes.categories.all'),
    value: 'all',
    indeterminate: false
  },
  {
    label: t('components.slides.quotes.categories.success'),
    value: 'success'
  },
  {
    label: t('components.slides.quotes.categories.wisdom'),
    value: 'wisdom'
  },
  {
    label: t('components.slides.quotes.categories.inspirational'),
    value: 'inspirational'
  },
  {
    label: t('components.slides.quotes.categories.happiness'),
    value: 'happiness'
  },
  {
    label: t('components.slides.quotes.categories.movies'),
    value: 'movies'
  },
  {
    label: t('components.slides.quotes.categories.love'),
    value: 'love'
  }
])
const prevState = ref([])
const categories = computed(() => {
  return checkedList.value.filter(l => l !== 'all')
})

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

const updateSlide = () => {
  emit('update-slide', slide)
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const updateSlideData = () => {
  slide.updateSlideData({
    categories: toRaw(categories.value)
  })
}

const onTopicsChange = e => {
  const categoriesLength = categoriesOptions.value.length - 1
  const selectAllPressed = !prevState.value.includes('all') && checkedList.value.includes('all')
  const deselectAllPressed = prevState.value.includes('all') && !checkedList.value.includes('all')
  if (!selectAllPressed && !deselectAllPressed && categoriesLength === checkedList.value.length && prevState.value.length !== categoriesLength + 1) {
    checkedList.value = categoriesOptions.value.map(({ value }) => value)
  } else if (selectAllPressed) {
    checkedList.value = categoriesOptions.value.map(({ value }) => value)
  } else if (deselectAllPressed) {
    checkedList.value = []
  } else {
    checkedList.value = checkedList.value.filter(value => value !== 'all')
  }
  prevState.value = checkedList.value
  categoriesOptions.value[0].indeterminate = !!checkedList.value.length && checkedList.value.length < categoriesLength

  if (!categories.value.length) {
    toggleDisabled(true)
    return
  }

  updateSlideData()
  updateSlide()
  toggleDisabled(false)
}

onTopicsChange()
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.quotes.slideStep1.subTitle')"
    :title="$t('components.slides.quotes.slideStep1.subTitle')"
    no-border
  >
    <a-checkbox-group
      v-model:value="checkedList"
      :options="categoriesOptions"
      class="multirow-checkbox-group"
      @change="onTopicsChange"
    />
  </SlideStep>
</template>

<style lang="less">

</style>
