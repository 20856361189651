import {
  GET_AVAILABLE_CALENDARS_BY_GOOGLE_SOCIAL_ACCOUNT_ID,
  GET_AVAILABLE_CALENDARS_BY_MICROSOFT_SOCIAL_ACCOUNT_ID, GET_GOOGLE_ACCESS_TOKEN,
  LIST_SOCIAL_ACCOUNTS
} from '@/graphql/queries'
import ApolloClient from '@/apollo'
import {
  LINK_SOCIAL_ACCOUNT_CANVA,
  LINK_SOCIAL_ACCOUNT_DROPBOX,
  LINK_SOCIAL_ACCOUNT_GOOGLE,
  LINK_SOCIAL_ACCOUNT_INSTAGRAM,
  LINK_SOCIAL_ACCOUNT_MICROSOFT,
  LINK_SOCIAL_ACCOUNT_TWITTER,
  UNLINK_SOCIAL_ACCOUNT
} from '@/graphql/mutations'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    socialAccounts: [],
    socialAccountsLoading: true
  },
  actions: {
    async getSocialAccounts ({ state, commit }) {
      state.socialAccountsLoading = true
      ApolloClient.query({ query: LIST_SOCIAL_ACCOUNTS, fetchPolicy: 'no-cache' }).then(({ data, errors }) => {
        commit('SET_SOCIAL_ACCOUNTS', data?.listSocialAccounts || [])
      }).catch(e => {
        state.socialAccountsLoading = false
      })
    },
    async linkSocialAccountCanva ({ getters, commit }, { token, nonce }) {
      try {
        await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_CANVA, variables: { input: { token }, nonce } })
      } catch (e) {
        handleError(e)
      }
    },
    async linkTwitterAccount ({ getters, commit }, payload) {
      try {
        const { data: { linkSocialAccountTwitter } } = await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_TWITTER, variables: { input: payload } })
        commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountTwitter])
      } catch (e) {
        handleError(e)
      }
    },
    async linkInstagramAccount ({ getters, commit }, payload) {
      try {
        const { data: { linkSocialAccountsInstagram } } = await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_INSTAGRAM, variables: { input: payload } })
        if (!linkSocialAccountsInstagram.length) {
          throw new Error('No available social accounts')
        }
        commit('ADD_SOCIAL_ACCOUNT', linkSocialAccountsInstagram)
      } catch (e) {
        handleError(e)
      }
    },
    async linkGoogleAccount ({ getters, commit }, payload) {
      try {
        const { data: { linkSocialAccountGoogle } } = await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_GOOGLE, variables: { input: payload } })
        commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountGoogle])
      } catch (e) {
        handleError(e)
      }
    },
    async getGoogleAccountAccessToken ({ getters, commit }, socialAccountId) {
      try {
        const { data: { getSocialAccountGoogleAccessToken: accessToken } } = await ApolloClient.query({ query: GET_GOOGLE_ACCESS_TOKEN, variables: { socialAccountId } })
        return accessToken
      } catch (e) {
        handleError(e)
      }
    },
    async linkMicrosoftAccount ({ getters, commit }, payload) {
      try {
        const { data: { linkSocialAccountMicrosoft } } = await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_MICROSOFT, variables: { input: payload } })
        commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountMicrosoft])
      } catch (e) {
        handleError(e)
      }
    },
    async linkDropboxAccount ({ getters, commit }, payload) {
      try {
        const { data: { linkSocialAccountDropbox } } = await ApolloClient.mutate({ mutation: LINK_SOCIAL_ACCOUNT_DROPBOX, variables: { input: payload } })
        commit('ADD_SOCIAL_ACCOUNT', [linkSocialAccountDropbox])
      } catch (e) {
        handleError(e)
      }
    },
    async unlinkSocialAccount ({ getters, commit }, payload) {
      const { id } = payload
      return ApolloClient.mutate({ mutation: UNLINK_SOCIAL_ACCOUNT, variables: { ...payload } }).then(()=>{
        commit('REMOVE_SOCIAL_ACCOUNT', id)
      })
    },
    async getAvailableGoogleCalendarBySocialAccountId (ctx, googleSocialAccountId) {
      return ApolloClient.query({ query: GET_AVAILABLE_CALENDARS_BY_GOOGLE_SOCIAL_ACCOUNT_ID, variables: { googleSocialAccountId } })
    },
    async getAvailableMicrosoftCalendarBySocialAccountId (ctx, microsoftSocialAccountId) {
      return ApolloClient.query({ query: GET_AVAILABLE_CALENDARS_BY_MICROSOFT_SOCIAL_ACCOUNT_ID, variables: { microsoftSocialAccountId } })
    }
  },
  getters: {
    socialAccounts: state => state.socialAccounts,
    socialAccountsLoading: state => state.socialAccountsLoading,
    socialTwitterAccounts: state => state.socialAccounts.filter(sa => sa.type === 'TWITTER'),
    socialGoogleAccounts: state => state.socialAccounts.filter(sa => sa.type === 'GOOGLE'),
    socialInstagramAccounts: state => state.socialAccounts.filter(sa => sa.type === 'INSTAGRAM'),
    socialMicrosoftAccounts: state => state.socialAccounts.filter(sa => sa.type === 'MICROSOFT'),
    socialDropboxAccounts: state => state.socialAccounts.filter(sa => sa.type === 'DROPBOX')
  },
  mutations: {
    SET_SOCIAL_ACCOUNTS (state, socialAccounts) {
      state.socialAccounts = socialAccounts
      state.socialAccountsLoading = false
    },
    ADD_SOCIAL_ACCOUNT (state, socialAccounts) {
      if (state.socialAccounts) {
        for (const sa of socialAccounts) {
          if (!state.socialAccounts.find(s => s.id === sa.id)) {
            state.socialAccounts.push(sa)
          }
        }
      }
    },
    REMOVE_SOCIAL_ACCOUNT (state, socialAccountId) {
      state.socialAccounts = [...state.socialAccounts.filter(sa => socialAccountId !== sa.id)]
    }
  }
}
