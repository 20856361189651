<template>
  <a-modal
    :open="showDevicePreview"
    :title="deviceName ? `${deviceName} Preview` : 'Preview'"
    width="688px"
    :footer="null"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <div class="video-container">
      <div
        v-if="showDevicePreview"
        class="video-info"
      >
        <LoadingOutlined
          v-if="!deviceOffline"
          class="loading-icon"
          size="large"
          type="primary"
        />
        <div
          v-else
          class="device-offline-info"
        >
          <EyeInvisibleOutlined />
          <a-typography-text style="color:#fff;">
            Device is offline
          </a-typography-text>
        </div>
      </div>
      <video
        v-if="playbackObject"
        ref="playback"
        width="640"
        height="480"
        style="position: relative; z-index: 2;"
        :srcObject.prop="playbackObject"
        autoplay
      />
    </div>
  </a-modal>
</template>

<script>

import { computed, defineComponent, ref, watch } from 'vue'
import { EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

const restartPause = 2000

const parseOffer = (offer) => {
  const ret = {
    iceUfrag: '',
    icePwd: '',
    medias: [],
  }

  for (const line of offer.split('\r\n')) {
    if (line.startsWith('m=')) {
      ret.medias.push(line.slice('m='.length))
    } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
      ret.iceUfrag = line.slice('a=ice-ufrag:'.length)
    } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
      ret.icePwd = line.slice('a=ice-pwd:'.length)
    }
  }

  return ret
}

const generateSdpFragment = (offerData, candidates) => {
  const candidatesByMedia = {}
  for (const candidate of candidates) {
    const mid = candidate.sdpMLineIndex
    if (candidatesByMedia[mid] === undefined) {
      candidatesByMedia[mid] = []
    }
    candidatesByMedia[mid].push(candidate)
  }

  let frag = 'a=ice-ufrag:' + offerData.iceUfrag + '\r\n'
      + 'a=ice-pwd:' + offerData.icePwd + '\r\n'

  let mid = 0

  for (const media of offerData.medias) {
    if (candidatesByMedia[mid] !== undefined) {
      frag += 'm=' + media + '\r\n'
          + 'a=mid:' + mid + '\r\n'

      for (const candidate of candidatesByMedia[mid]) {
        frag += 'a=' + candidate.candidate + '\r\n'
      }
    }
    mid++
  }

  return frag
}

export default defineComponent({
  name: 'LivePreviewModal',
  components: {
    LoadingOutlined,
    EyeInvisibleOutlined
  },
  setup () {
    const store = useStore()
    const playbackObject = ref(null)
    const whepClient = ref(null)
    const pc = ref(null)
    const restartTimeout = ref(null)
    const eTag = ref('')
    const queuedCandidates = ref([])
    const offerData = ref(null)
    const mediamtxServer = ref(null)
    const streamKey = ref(null)
    const showDevicePreview = computed(() => store.getters['devices/devicePreviewObjectIsSet'])
    const deviceName = computed(() => store.getters['devices/devicePreviewDeviceName'])
    const deviceId = computed(() => store.getters['devices/devicePreviewDeviceId'])
    const deviceOffline = ref(false)

    const startWHEPClient = () => {
      if (!deviceId.value) return
      store.dispatch('devices/initializeDevicePreview', { deviceId: deviceId.value })
          .then(async (res) => {
            mediamtxServer.value = res.server
            streamKey.value = res.server
          })
          .then(() => store.dispatch('devices/getDevicePreviewWebrtcIceServers', { deviceId: deviceId.value }))
          .then(onIceServers)
          .catch((err) => {
            if (err.message === 'FAILED_PREVIEW_OFFLINE_DEVICE') {
              deviceOffline.value = true
            }
            else  {
              console.log(err.message)
            }
            scheduleRestart()
          })
    }

    const onIceServers = (iceServers) => {
      if (!deviceId.value) return
      pc.value = new RTCPeerConnection({
        iceServers
      })

      const direction = 'sendrecv'
      pc.value.addTransceiver('video', { direction })
      pc.value.addTransceiver('audio', { direction })

      pc.value.onicecandidate = (evt) => onLocalCandidate(evt)
      pc.value.oniceconnectionstatechange = () => onConnectionState()

      pc.value.ontrack = (evt) => {
        playbackObject.value = evt.streams[0]
      }

      pc.value.createOffer()
        .then((desc) => {
          offerData.value = parseOffer(desc.sdp)
          pc.value.setLocalDescription(desc)
          store.dispatch('devices/sendDevicePreviewWebrtcOffer', {
            deviceId: deviceId.value,
            offerSdp: desc.sdp
          })
              .then(async (res) => {
                // throw new Error('stop.');
                eTag.value = res.eTag
                return res.answerSdp
              })
              .then((sdp) => onRemoteDescription(new RTCSessionDescription({
                type: 'answer',
                sdp,
              })))
              .catch((err) => {
                console.log(err.message)
                scheduleRestart()
              })
        })
    }

    const onConnectionState = () => {
      if (restartTimeout.value !== null) {
        return
      }
      if (!pc.value) return

      switch (pc.value.iceConnectionState) {
        case 'disconnected':
          scheduleRestart()
      }
    }

    const onRemoteDescription = (answer) => {
      if (restartTimeout.value !== null) {
        return
      }

      pc.value.setRemoteDescription(new RTCSessionDescription(answer))

      if (queuedCandidates.value?.length !== 0) {
        sendLocalCandidates(queuedCandidates.value)
        queuedCandidates.value = []
      }
    }

    const onLocalCandidate = (evt) => {
      if (restartTimeout.value !== null) {
        return
      }

      if (evt.candidate !== null) {
        if (eTag.value === '') {
          queuedCandidates.value.push(evt.candidate)
        } else {
          sendLocalCandidates([evt.candidate])
        }
      }
    }

    const sendLocalCandidates = (candidates) => {
      store.dispatch('devices/sendDevicePreviewWebrtcLocalCandidates', {
        deviceId: deviceId.value,
        sdpFragment: generateSdpFragment(offerData.value, candidates),
        eTag: eTag.value
      })
          .catch((err) => {
            console.log(err.message)
            scheduleRestart()
          })
    }

    const scheduleRestart = () => {
      if (!showDevicePreview.value) return
      if (restartTimeout.value !== null) return

      if (pc.value !== null) {
        pc.value?.close()
        pc.value = null
      }
      eTag.value = ''
      queuedCandidates.value = []

      restartTimeout.value = window.setTimeout(() => {
        restartTimeout.value = null
        startWHEPClient()
      }, restartPause)


    }

    const closeModal = () => {
      eTag.value = ''
      pc.value = null
      offerData.value = null
      streamKey.value = null
      whepClient.value = null
      restartTimeout.value = null
      playbackObject.value = null
      mediamtxServer.value = null
      deviceOffline.value = false
      queuedCandidates.value = []
      store.commit('devices/CLEAR_DEVICE_PREVIEW_OBJECT')
    }

    watch(() => deviceId.value, (value) => {
      value && startWHEPClient()
    })

    return {
      playbackObject,
      showDevicePreview,
      deviceId,
      deviceName,
      deviceOffline,
      closeModal
    }
  }
})
</script>

<style lang="less">
.video-container {
  width: 640px;
  height: 480px;
  background: rgba(0,0,0,0.7);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%;
    .loading-icon {
      height: 32px;
      font-size: 32px;
      margin: auto;
      color: var(--ant-primary-color);
    }
    .device-offline-info {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 20px;
      .anticon {
        font-size: 32px;
        margin-right: 8px;
        color: var(--ant-error-color);
      }
    }
  }

  video {
    z-index: 2;
  }
}
</style>
