<script setup>
import { reactive, toRaw } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { useI18n } from 'vue-i18n'
import { HoroscopesSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: HoroscopesSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const HOROSCOPE_SIGNS = ['aries','taurus','gemini','cancer','leo','virgo','libra','scorpio','sagittarius','capricorn','aquarius','pisces']

const { t } = useI18n()
const slide = props.slideObj || new HoroscopesSlide()
const { signs, period, horoscopeType} = slide.slideData
const horoscopeState = reactive({
  signs,
  period,
  horoscopeType
})

const signOptions = HOROSCOPE_SIGNS.map((s)=>({ value: s, label: s}))

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

const updateSlide = () => {
  emit('update-slide', slide)
}

const updateSlideData = () => {
  slide.updateSlideData(toRaw(horoscopeState))
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const handleSignSelect = () => {
  if (!horoscopeState.signs.length) {
    return toggleDisabled(true)
  }
  updateSlideData()
  updateSlide()
  toggleDisabled(false)
}

if (!slide.widgetId) {
  handleSignSelect()
}

</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.horoscopes.slideStep1.subTitle')"
    :title="$t('components.slides.horoscopes.slideStep1.title')"
    no-border
  >
    <a-select
      v-model:value="horoscopeState.signs"
      style="width: 100%"
      mode="multiple"
      allow-clear
      :options="signOptions"
      auto-clear-search-value="true"
      :placeholder="$t('components.slides.horoscopes.slideStep1.placeholder')"
      @select="handleSignSelect"
      @deselect="handleSignSelect"
    />
  </SlideStep>
</template>

<style lang="less">

</style>
