<template>
  <a-layout-header
    style="height: 42px; overflow: hidden; line-height: 42px; text-align: center; color: #fff;"
    class="dashboard-info-header"
    :class="type"
  >
    <slot />
  </a-layout-header>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoHeader',
  props: {
    type: {
      type: String,
      default: () => 'default',
      validator: function (value) {
        return ['default', 'warning', 'danger'].includes(value)
      }
    }
  },
  setup () {
    return {}
  }
})
</script>

<style scoped lang="less">
.dashboard-info-header {
  &.default {
      background-color: var(--ant-primary-7);
  }
  &.danger {
    background-color: var(--alert-bg-color);
  }
  &.black {
    background-color: black;
  }
  &.warning {
    background-color: var(--ant-warning-color);
  }
}

</style>
