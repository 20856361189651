import EmergencySettings from '@/components/globalSettings/EmergencySettings'
import NotificationsSettings from '@/components/globalSettings/NotificationsSettings'
import GeneralSettings from '@/components/globalSettings/GeneralSettings'
import WatermarkSettings from '@/components/globalSettings/WatermarkSettings'
import ProfileSettings from '@/components/globalSettings/ProfileSettings'
import SecuritySettings from '@/components/globalSettings/securitySettings/SecuritySettings.vue'
import SubscriptionSettings from '@/components/globalSettings/SubscriptionSettings'
import BrandingSettings from '@/components/globalSettings/BrandingSettings'
import ChangeLogs from '@/components/globalSettings/ChangeLogs'
import ExternalApiSettings from '@/components/globalSettings/ExternalApiSettings.vue'

export default {
  EmergencySettings,
  NotificationsSettings,
  GeneralSettings,
  WatermarkSettings,
  ProfileSettings,
  SecuritySettings,
  SubscriptionSettings,
  BrandingSettings,
  ChangeLogs,
  ExternalApiSettings
}
