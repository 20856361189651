import { cloneDeep } from '@apollo/client/utilities'
import ApolloClient from '@/apollo'
import { GET_MEDIA_BY_ID } from '@/graphql/queries'
import { ref } from 'vue'
import { AI_COLOUR_PALETTE, DEFAULT_DURATION_PER_SLIDE_ITEM, DEFAULT_SLIDE_DURATION, MIME_TYPES_MAP } from '@/constants'
import { countriesMap } from '@/helpers/Countries'
import mime from 'mime-types'
import { i18n } from '@/i18n'

const { t } = i18n.global
const MEDIA_POLL_INTERVAL = 3000
const REGEX_PATH = /(.+)*\.(\w+)$/

const incompleteMediaSubs = {}

const getMediaType = (mimetype) => {
  const mediaTypeFull = MIME_TYPES_MAP[mimetype]
  return ['IMAGE', 'VIDEO', 'DOCUMENT'].find(type => mediaTypeFull?.startsWith(type)) || 'NOT_MEDIA'
}

const CALENDAR_VIEW_TO_L10N_KEY_MAP = {
  DAY: 'dayView',
  WEEK: 'weekView',
  MONTH: 'monthView'
}

export const stripObjTypename = (obj) => {
  delete obj.__typename
  const stripObj = (obj) => {
    for (const a in obj) {
      if (obj[a] && obj[a].__typename) {
        delete obj[a].__typename
      }
      if (typeof obj[a] === 'object') {
        stripObj(obj[a])
      }
    }
    return obj
  }
  return stripObj(obj)
}

const subscribeSlideToIncompleteMediaWithAssets = (id, slide) => {
  if (!incompleteMediaSubs[id]) {
    incompleteMediaSubs[id] = {
      sub: ApolloClient.watchQuery({
        query: GET_MEDIA_BY_ID,
        variables: { id },
        pollInterval: MEDIA_POLL_INTERVAL,
        fetchPolicy: 'no-cache'
      }).subscribe(({
        data: { getWorkspaceMediaById: asset },
        loading
      }) => {
        const thumbnail = getThumbnailFromRawAsset(asset)
        const source = getThumbnailFromRawAsset(asset)
        if (thumbnail) {
          incompleteMediaSubs[id].slides.forEach(slide => {
            slide.updateAssetById(id, {
              thumbnail,
              source,
              metadata: asset.metadata
            })
          })
          incompleteMediaSubs[id].sub.unsubscribe()
          delete incompleteMediaSubs[id]
        }
      }),
      slides: [slide]
    }
  } else {
    incompleteMediaSubs[id].slides.push(slide)
  }
}

export function createSlides (slides) {
  return slides.map(slide => {
    const type = slide.widget?.type
    const SlideClass = createSlideByType(type, slide)
    if (!SlideClass) {
      return
    }
    return SlideClass
  })
}

export function createSlideByType (type, slide) {
  const SlideClass = slideTypes[type]
  return new SlideClass(slide)
}

export class SlideAsset {
  id
  sourceType
  thumbnail
  metadata
  fullName
  name
  extension
  mediaReference

  constructor ({
    id,
    thumbnail,
    source,
    metadata,
    name,
    mediaReference,
    sourceType
  }) {
    this.id = id
    this.thumbnail = thumbnail
    this.source = source
    this.sourceType = sourceType
    this.metadata = metadata
    this.mediaReference = mediaReference
    this.fullName = name
    const nameMatch = name && name.match(REGEX_PATH)
    const fileMimeType = mime.lookup(name)
    this.mimeType = fileMimeType === 'video/quicktime' ? 'video/mp4' : fileMimeType
    this.mediaType = getMediaType(fileMimeType)
    this.name = nameMatch ? nameMatch[1] : 'Undefined'
    this.extension = nameMatch ? nameMatch[2] : null
  }
}

export class Slide {
  static icon = 'picture-outlined'
  static type = 'ImageType'
  static nameKey = 'image'
  isMutable = false
  hasObjectFit = false
  waitingForUpdate = false
  hasFullPreviewUrl = false
  dbType
  widgetType
  highlighted = false
  editOnlyMode = false
  slideData

  constructor ({
    slide: {
      id,
      duration = DEFAULT_SLIDE_DURATION,
      tags = null,
      paused = false,
      widgetId,
      name,
      widget = null,
      editOnlyMode = false,
      highlighted = false
    } = {},
    isMutable,
    hasObjectFit,
    dbType,
    widgetType
  }) {
    this.id = id
    this.slideKey = new Date().getTime() + (id || 'newSlide')
    this.duration = duration
    this.tags = tags
    this.paused = paused
    this.widgetId = widgetId
    this.name = name
    this.widget = widget
    this.editOnlyMode = editOnlyMode
    this.highlighted = highlighted
    this.isMutable = isMutable || this.isMutable
    this.hasObjectFit = hasObjectFit || this.hasObjectFit
    this.dbType = dbType
    this.widgetType = widgetType
  }

  updateSlide (slide = {}) {
    const {
      widget = null
    } = slide
    if (this.waitingForUpdate) {
      return createSlideByType(widget.type, slide)
    }
    return this
  }

  setName = (name) => {
    this.name = name
  }
  setTags = (tags) => {
    this.tags = tags
  }

  setWidgetId = (widgetId) => {
    this.widgetId = widgetId
  }

  setDuration = (duration) => {
    this.duration = duration || DEFAULT_SLIDE_DURATION
  }

  getName = () => {
    return this.name
  }

  setSlideData = (value) => {
    this.slideData = value
  }

  updateSlideData = (value) => {
    this.slideData = { ...this.slideData, ...value }
  }

  setWaitingForUpdate = (value) => {
    this.waitingForUpdate = value
  }

  setHighlighted = (value) => {
    this.highlighted = value
  }

  setEditOnlyMode = (value) => {
    this.editOnlyMode = value
  }

  setMute = (muted) => {
    this.slideData.muted = muted
  }

  setObjectFit = (objectFit) => {
    this.slideData.objectFit = objectFit
  }

  setPaused = (paused) => {
    this.paused = paused
  }

  getDto ({ wrapperData, ignoreWidgetId } = {}) {
    return {
      duration: this.duration,
      paused: this.paused,
      name: this.name,
      tags: this.tags,
      ...(ignoreWidgetId ? null : this.widgetId ? { widgetId: this.widgetId } : null),
      createWidgetWrapper: {
        [this.dbType]: wrapperData || this.slideData
      }
    }
  }

  getDtoWithoutWrapper (ignoreWidgetId) {
    return {
      ...(this.name ? { name: this.name } : null),
      ...(this.tags ? { tags: this.tags } : null),
      duration: this.duration,
      paused: this.paused,
      ...(ignoreWidgetId ? null : this.widgetId ? { widgetId: this.widgetId } : null),
    }
  }

  getDescription = () => {
    return this.name
  }

  getError = () => {
    return null
  }

  getIcon = () => {
    return this.constructor.icon
  }

  getFullPreviewUrl = () => {
    return null
  }

  getDefaultName = () => t(`slides.${this.constructor.nameKey}`) || this.name

  getNameString = () => {
    const defaultName = this.getDefaultName()
    return this.name && this.name !== defaultName
      ? `${this.name} (${defaultName})`
      : defaultName
  }

}

export class ImageSlide extends Slide {
  static icon = 'picture-outlined'
  static type = 'ImageSlide'
  static nameKey = 'images'

  settings = {
    defaultSlideData: {
      view: 'FULLSCREEN',
      shuffle: false,
      objectFit: 'FIT',
      mediaImageReferences: [],
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData
  images = ref([])

  constructor (slide) {
    super({
      slide: { ...slide },
      widgetType: 'MEDIA_IMAGES',
      hasObjectFit: true,
      dbType: 'widgetMediaImages'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.data
      ? {
        view: widget.data?.view,
        objectFit: widget.data?.objectFit || this.settings.defaultSlideData.objectFit,
        shuffle: widget.data?.shuffle,
        mediaImageReferences: widget?.data?.mediaImageReferences || [],
        durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem
      }
      : this.settings.defaultSlideData
    this.#initImages()
  }

  getDescription = () => {
    return this.slideData?.mediaImageReferences?.length + ' Image(s)'
  }

  getPreviewUrl = () => {
    if (this.images.value?.length === 1) {
      const thumbnail = this.images.value?.[0]?.thumbnail
      return thumbnail || this.widget?.mediaImages?.[0]?.generatedMedia?.find(m => m.tag === 'thumbnail')?.url || require('@/assets/images/slides/image.png')
    }
    return require('@/assets/images/slides/image.png')
  }

  setImages = (images) => {
    this.images.value = images
    this.#subscribeToAssetsIfSomeMissing()
  }

  #initImages = () => {
    const images = this.widget?.mediaImages || []
    this.images.value = images.map(asset => {
      const mediaReference = cloneDeep(this.slideData?.mediaImageReferences?.find(ref => {
        return asset.sourceType ? (asset.sourceType === ref.sourceType && ref.externalMediaId === asset.id) : ref.workspaceMediaId === asset.id
      }))
      if (!mediaReference) return null
      return new SlideAsset({
        id: asset.id,
        sourceType: asset.sourceType,
        metadata: asset.metadata,
        thumbnail: getThumbnailFromRawAsset(asset),
        source: getSourceFromRawAsset(asset),
        name: asset.name || asset.dropboxAdditionalInfo?.name || asset.gDriveAdditionalInfo?.name,
        mediaReference
      })
    })
  }

  #subscribeToAssetsIfSomeMissing = () => {
    const assetsWithMissingThumbnails = this.images.value?.filter(a => !a.thumbnail)
    if (assetsWithMissingThumbnails?.length > 0) {
      assetsWithMissingThumbnails.forEach(({ id }) => {
        this.#subscribeToIncompleteMediaWithAssets(id)
      })
    }
  }

  #subscribeToIncompleteMediaWithAssets = (id) => {
    subscribeSlideToIncompleteMediaWithAssets(id, this)
  }

  updateAssetById = (id, assetProps) => {
    const asset = this.images.value?.find(a => a.id === id)
    Object.assign(asset, assetProps)
  }

}

export class VideoSlide extends Slide {
  static icon = 'play-square-outlined'
  static nameKey = 'video'
  static type = 'VideoSlide'
  settings = {
    defaultSlideData: {
      muted: false,
      objectFit: 'FIT',
      mediaVideoReference: null

    }
  }
  slideData
  video = ref(null)
  customDuration

  constructor (slide) {
    super({
      slide,
      widgetType: 'MEDIA_VIDEO',
      isMutable: true,
      hasObjectFit: true,
      dbType: 'widgetMediaVideo'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.data
      ? {
        muted: !!widget?.data?.muted || this.settings.defaultSlideData.muted,
        objectFit: widget.data?.objectFit || this.settings.defaultSlideData.objectFit,
        mediaVideoReference: widget?.data?.mediaVideoReference || null
      }
      : this.settings.defaultSlideData
    this.#initVideo()
    this.customDuration = this.video?.value?.metadata?.duration
  }

  getDescription = () => {
    return this.widget?.mediaVideo?.name || this.widget?.mediaVideo?.dropboxAdditionalInfo?.name || this.widget?.mediaVideo?.gDriveAdditionalInfo?.name
  }

  getPreviewUrl = () => {
    const thumbnail = this.video?.thumbnail
    return thumbnail || this.widget?.mediaVideo?.generatedMedia?.find(m => m.tag === 'thumbnail')?.url || require('@/assets/images/slides/video.png')
  }

  setVideo = (video) => {
    this.video.value = video
    this.#subscribeToAssetsIfSomeMissing()
  }

  #initVideo = () => {
    const video = this.widget?.mediaVideo
    if (!video) return
    this.video.value = new SlideAsset({
      id: video.id,
      sourceType: video.sourceType,
      metadata: video.metadata,
      thumbnail: getThumbnailFromRawAsset(video),
      source: getSourceFromRawAsset(video),
      name: video.name || video.dropboxAdditionalInfo?.name || video.gDriveAdditionalInfo?.name,
      mediaReference: this.slideData.mediaVideoReference
    })
  }

  #subscribeToAssetsIfSomeMissing = () => {
    if (!this.video?.value?.thumbnail) {
      this.#subscribeToIncompleteMediaWithAssets(this.video?.value?.id)
    }
  }

  #subscribeToIncompleteMediaWithAssets = (id) => {
    subscribeSlideToIncompleteMediaWithAssets(id, this)
  }

  updateAssetById = (id, assetProps) => {
    if (this.video.id === id) {
      Object.assign(this.video, assetProps)
    }
  }
}

export class DocumentSlide extends Slide {
  static icon = 'paper-clip-outlined'
  static nameKey = 'document'
  static type = 'DocumentSlide'
  settings = {
    defaultSlideData: {
      mediaDocumentReference: null,
      objectFit: 'FIT',
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  document = ref(null)

  constructor (slide) {
    super({
      slide,
      widgetType: 'MEDIA_DOCUMENT',
      dbType: 'widgetMediaDocument',
      hasObjectFit: true
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      mediaDocumentReference: widget?.data?.mediaDocumentReference || null,
      objectFit: widget?.data?.objectFit || this.settings.defaultSlideData.objectFit,
      durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem
    }
    this.#initDocument()
  }

  getDescription = () => {
    return this.widget?.mediaDocument?.name || this.widget?.mediaDocument?.dropboxAdditionalInfo?.name || this.widget?.mediaDocument?.gDriveAdditionalInfo?.name
  }

  getPreviewUrl = () => {
    const thumbnail = this.document?.[0]?.thumbnail
    return thumbnail || this.widget?.mediaDocument?.generatedMedia?.find(m => m.tag === 'thumbnail')?.url || require('@/assets/images/slides/document.png')
  }

  setDocument = (document) => {
    this.document.value = document
    this.#subscribeToAssetsIfSomeMissing()
  }

  #initDocument = () => {
    const document = this.widget?.mediaDocument
    if (!document) return
    this.document.value = new SlideAsset({
      id: document.id,
      sourceType: document.sourceType,
      metadata: document.metadata,
      thumbnail: getThumbnailFromRawAsset(document),
      source: getSourceFromRawAsset(document),
      name: document.name || document.dropboxAdditionalInfo?.name || document.gDriveAdditionalInfo?.name,
      mediaReference: this.slideData.mediaDocumentReference
    })
  }

  #subscribeToAssetsIfSomeMissing = () => {
    if (!this.document?.value?.thumbnail) {
      this.#subscribeToIncompleteMediaWithAssets(this.document?.value?.id)
    }
  }

  #subscribeToIncompleteMediaWithAssets = (id) => {
    subscribeSlideToIncompleteMediaWithAssets(id, this)
  }

  updateAssetById = (id, assetProps) => {
    if (this.document.id === id) {
      Object.assign(this.document, assetProps)
    }
  }

}

export class StreamSlide extends Slide {
  static icon = 'youtube-outlined'
  static nameKey = 'stream'
  static type = 'StreamSlide'
  settings = {
    defaultSlideData: {
      muted: false,
      url: '',
      thumbnailUrl: null,
      ccEnabled: false,
      forcedCaptions: null,
      objectFit: 'FIT',
    }
  }
  slideData
  customDuration

  constructor (slide) {
    super({
      slide,
      widgetType: 'STREAM',
      dbType: 'widgetStream',
      isMutable: true,
      hasObjectFit: true
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.data
      ? {
        muted: !!widget?.data?.muted || this.settings.defaultSlideData.muted,
        duration: widget?.data?.duration,
        url: widget?.data?.url || '',
        ccEnabled: !!widget?.data?.ccEnabled,
        forcedCaptions: widget?.data?.forcedCaptions || null,
        thumbnailUrl: widget?.data?.thumbnailUrl || null,
        objectFit: widget?.data?.objectFit || this.settings.defaultSlideData.objectFit
      }
      : this.settings.defaultSlideData
    this.customDuration = widget?.data?.duration || null
  }

  getDescription = () => {
    return this.widget?.data?.url
  }

  getPreviewUrl = () => {
    return this.widget?.data?.thumbnailUrl || require('@/assets/images/slides/stream.png')
  }
}

export class GoogleCalendarSlide extends Slide {
  static icon = 'calendar-outlined'
  static nameKey = 'googleCalendar'
  static type = 'CalendarGoogleSlide'
  settings = {
    defaultSlideData: {
      view: 'DAY',
      socialGoogleAccountId: null,
      calendarIds: []
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'CALENDAR_GOOGLE',
      dbType: 'widgetCalendarGoogle'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.googleCalendar
      ? {
        view: widget?.googleCalendar?.view || 'DAY',
        socialGoogleAccountId: widget?.googleCalendar?.socialGoogleAccountId,
        calendarIds: widget?.googleCalendar?.calendarIds || []
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.widget?.googleCalendars?.list?.map(({ summary }) => summary) || 'Calendar'
  }

  getError = () => {
    return this.widget?.googleCalendars?.message
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/calendar.png')
  }

  getNameString = () => {
    return (this.name
      ? this.name === this.getDefaultName()
        ? this.name
        : `${this.name} (${this.getDefaultName()})`
      : this.getDefaultName())
      + ' - ' + t(`components.slides.calendarGoogle.${CALENDAR_VIEW_TO_L10N_KEY_MAP[this.slideData.view]}`)
  }
}

export class MicrosoftCalendarSlide extends Slide {
  static icon = 'calendar-outlined'
  static nameKey = 'microsoftCalendar'
  static type = 'CalendarMicrosoftSlide'
  settings = {
    defaultSlideData: {
      view: 'DAY',
      socialMicrosoftAccountId: null,
      calendarIds: []
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'CALENDAR_MICROSOFT',
      dbType: 'widgetCalendarMicrosoft'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.microsoftCalendar
      ? {
        view: widget?.microsoftCalendar?.view || 'DAY',
        socialMicrosoftAccountId: widget?.microsoftCalendar?.socialMicrosoftAccountId,
        calendarIds: widget?.microsoftCalendar?.calendarIds || []
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.widget?.microsoftCalendars?.list?.map(({ name }) => name) || 'Calendar'
  }

  getError = () => {
    return this.widget?.microsoftCalendars?.message
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/calendar.png')
  }

  getNameString = () => {
    return (this.name
        ? this.name === this.getDefaultName()
          ? this.name
          : `${this.name} (${this.getDefaultName()})`
        : this.getDefaultName())
      + ' - ' + t(`components.slides.calendarMicrosoft.${CALENDAR_VIEW_TO_L10N_KEY_MAP[this.slideData.view]}`)
  }

}

export class WebsiteSlide extends Slide {
  static icon = 'link-outlined'
  static nameKey = 'website'
  static type = 'WebsiteSlide'
  settings = {
    defaultSlideData: {
      url: ''
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'WEBSITE',
      dbType: 'widgetWebsite'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.data
      ? {
        url: widget?.data?.url || ''
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.widget?.data?.url
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/website.png')
  }

}

export class AIContentSlide extends Slide {
  static icon = 'thunderbolt-outlined'
  static nameKey = 'ai'
  static type = 'AISlide'
  settings = {
    defaultSlideData: {
      pexelsQuery: null,
      openaiPrompt: null,
      pexelsPhotoId: null,
      pexelsVideoId: null,
      mediaReference: null,
      text: {
        value: '',
        position: {
          vertical: 'CENTER',
          horizontal: 'CENTER'
        },
        font: {
          face: 'Helvetica',
          size: 20,
          color: AI_COLOUR_PALETTE[0].color
        },
        background: {
          color: AI_COLOUR_PALETTE[0].bgColor,
          opacity: .8
        }
      }
    }
  }
  slideData
  pexelsPhoto
  pexelsVideo

  constructor (slide) {
    super({
      slide,
      widgetType: 'AI_CONTENT',
      dbType: 'widgetAIContent'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.data
      ? {
        pexelsQuery: widget?.data?.pexelsQuery || null,
        openaiPrompt: widget?.data?.openaiPrompt || null,
        pexelsPhotoId: widget?.data?.pexelsPhotoId || null,
        pexelsVideoId: widget?.data?.pexelsVideoId || null,
        mediaReference: widget?.data?.mediaReference || null,
        text: widget?.data?.text
      }
      : this.settings.defaultSlideData
    this.pexelsVideo = widget?.pexelsVideo || null
    this.pexelsPhoto = widget?.pexelsPhoto || null
  }

  getDescription = () => {
    return 'AI Content'
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/stream.png')
  }

  setPexelsPhoto = (pexelsPhoto) => {
    this.pexelsPhoto = pexelsPhoto
  }

  setPexelsVideo = (pexelsVideo) => {
    this.pexelsVideo = pexelsVideo
  }
}

export class WeatherSlide extends Slide {
  static icon = 'cloud-outlined'
  static nameKey = 'weatherForecast'
  static type = 'WeatherSlide'
  settings = {
    defaultSlideData: {
      location: null,
      metric: false
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'WEATHER',
      dbType: 'widgetWeather'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      location: widget?.data?.location || this.settings.defaultSlideData.location,
      metric: widget?.data?.metric || this.settings.defaultSlideData.metric
    }
  }

  getDescription = () => {
    return this.slideData?.location?.address ?? 'Device location'
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/weather.png')
  }

}

export class WorldClockSlide extends Slide {
  static icon = 'clock-circle-outlined'
  static nameKey = 'worldClock'
  static type = 'ClockSlide'
  settings = {
    defaultSlideData: {
      locations: []
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'WORLD_CLOCK',
      dbType: 'widgetWorldClock'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      locations: widget?.data?.locations || this.settings.defaultSlideData.locations
    }
  }

  getDescription = () => {
    return this.slideData.locations?.map(l => l.timezone?.split('/').pop().replace('_', ' '))
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/world-clock.png')
  }

}

export class QuotesSlide extends Slide {
  static icon = 'message-outlined'
  static nameKey = 'quotes'
  static type = 'QuotesSlide'
  settings = {
    defaultSlideData: {
      categories: ['success'],
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'QUOTES',
      dbType: 'widgetQuotes'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      categories: widget?.data?.categories || this.settings.defaultSlideData.categories,
      durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem
    }
  }

  getDescription = () => {
    return this.slideData.categories?.map(category => t(`components.slides.quotes.categories.${category}`) || category)
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/quote.png')
  }

}

export class NewsSlide extends Slide {
  static icon = 'global-outlined'
  static nameKey = 'news'
  static type = 'NewsSlide'
  settings = {
    defaultSlideData: {
      topics: ['business'],
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM,
      countryCode: null
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'NEWS',
      dbType: 'widgetNews'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      topics: widget?.data?.topics || this.settings.defaultSlideData.topics,
      durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem,
      countryCode: widget?.data?.countryCode || this.settings.defaultSlideData.countryCode
    }
  }

  getDescription = () => {
    return this.slideData.topics?.map(topic => t(`components.slides.news.categories.${topic}`) || topic)
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/news.png')
  }

}

export class FeedSlide extends Slide {
  static icon = 'notification-outlined'
  static nameKey = 'rssFeed'
  static type = 'FeedSlide'
  settings = {
    defaultSlideData: {
      url: '',
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'FEED',
      dbType: 'widgetFeed'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      url: widget?.data?.url || this.settings.defaultSlideData.url,
      durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem
    }
  }

  getDescription = () => {
    return this.slideData.url
  }
  getPreviewUrl = () => {
    return require('@/assets/images/slides/feed.png')
  }

}

export class YelpSlide extends Slide {
  static icon = 'rest-outlined'
  static nameKey = 'yelp'
  static type = 'YelpSlide'
  settings = {
    defaultSlideData: {
      businessIdOrAlias: '',
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'YELP',
      dbType: 'widgetYelp'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      businessIdOrAlias: widget?.data?.businessIdOrAlias || this.settings.defaultSlideData.businessIdOrAlias,
      durationPerItem: widget?.data?.durationPerItem || this.settings.defaultSlideData.durationPerItem
    }
  }

  getDescription = () => {
    return this.slideData.businessIdOrAlias
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/yelp.png')
  }

}

export class TripAdvisorSlide extends Slide {
  static icon = 'rest-outlined'
  static nameKey = 'tripAdvisor'
  static type = 'TripAdvisorSlide'
  settings = {
    defaultSlideData: {
      locationId: ''
    }
  }
  slideData
  locationDetails

  constructor (slide) {
    super({
      slide,
      widgetType: 'TRIP_ADVISOR',
      dbType: 'widgetTripAdvisor'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      locationId: widget?.data?.locationId || this.settings.defaultSlideData.locationId
    }
    this.locationDetails = widget?.locationDetails || null
  }

  getDescription = () => {
    return `${this.locationDetails?.name} [${this.locationDetails?.address_obj?.address_string}]`
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/trip-advisor.png')
  }

}

export class GoogleReviewsSlide extends Slide {
  static icon = 'rest-outlined'
  static nameKey = 'google_reviews'
  static type = 'GoogleReviews'
  settings = {
    defaultSlideData: {
      placeId: null
    }
  }
  slideData
  placeDetails

  constructor (slide) {
    super({
      slide,
      widgetType: 'GOOGLE_REVIEWS',
      dbType: 'widgetGoogleReviews'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      placeId: widget?.data?.placeId || null
    }
    this.placeDetails = widget?.placeDetails || null
  }

  getDescription = () => {
    return this.placeDetails?.name
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/review.png')
  }

}

export class SocialInstagramSlide extends Slide {
  static icon = 'instagram-outlined'
  static nameKey = 'instagramFeed'
  static type = 'SocialInstagramSlide'
  settings = {
    defaultSlideData: {
      socialInstagramAccountIds: [],
      feedType: 'TOP',
      view: 'SINGLE',
      hashtags: [],
      muted: false,
      includeOwnInstagramMedia: false,
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'SOCIAL_INSTAGRAM',
      dbType: 'widgetSocialInstagram',
      isMutable: true,
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.socialInstagram
      ? {
        socialInstagramAccountIds: widget?.socialInstagram?.socialInstagramAccountIds,
        hashtags: widget?.socialInstagram?.hashtags,
        feedType: widget?.socialInstagram?.feedType,
        includeOwnInstagramMedia: widget?.socialInstagram?.includeOwnInstagramMedia,
        view: widget?.socialInstagram?.view,
        muted: !!widget?.socialInstagram?.muted || false,
        durationPerItem: widget?.socialInstagram?.durationPerItem || this.settings.defaultSlideData.duration
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.slideData.hashtags?.length > 0 ? [...this.slideData.includeOwnInstagramMedia ? ['+ Own posts'] : [], ...this.slideData.hashtags?.map(h => h.replace('#', ''))] : 'Showing own posts'
  }

  getPreviewUrl = () => {
    const previewFileName = this.slideData.view === 'SINGLE' ? 'social-feed.png' : 'social-photowall.png'
    return require(`@/assets/images/slides/${previewFileName}`)
  }

}

export class SocialTwitterSlide extends Slide {
  static icon = 'twitter-outlined'
  static nameKey = 'twitterFeed'
  static type = 'SocialTwitterSlide'
  settings = {
    defaultSlideData: {
      socialTwitterAccountIds: [],
      feedType: 'TOP',
      hashtags: [],
      includeOwnTweets: false,
      muted: false,
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'SOCIAL_TWITTER',
      dbType: 'widgetSocialTwitter',
      isMutable: true,
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.socialTwitter
      ? {
        socialTwitterAccountIds: widget?.socialTwitter?.socialTwitterAccountIds,
        hashtags: widget?.socialTwitter?.hashtags,
        feedType: widget?.socialTwitter?.feedType,
        includeOwnTweets: widget?.socialTwitter?.includeOwnTweets,
        muted: !!widget?.socialTwitter?.muted || this.settings.defaultSlideData.muted,
        durationPerItem: widget?.socialTwitter?.durationPerItem || this.settings.defaultSlideData.duration
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.slideData.hashtags?.length > 0 ? [...this.slideData.includeOwnTweets ? ['+ Own posts'] : [], ...this.slideData.hashtags?.map(h => h.replace('#', ''))] : 'Showing own posts'
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/twitter.png')
  }

}

export class SocialTiktokSlide extends Slide {
  static icon = 'tiktok-outlined'
  static nameKey = 'tiktokFeed'
  static type = 'SocialTiktokSlide'
  settings = {
    defaultSlideData: {
      hashtags: null,
      mentions: null,
      maxTimeMsPerVideo: null,
      muted: false
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'SOCIAL_TIKTOK',
      dbType: 'widgetSocialTiktok',
      isMutable: true,
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      hashtags: widget?.socialTiktok?.hashtags || this.settings.defaultSlideData.hashtags,
      mentions: widget?.socialTiktok?.mentions || this.settings.defaultSlideData.mentions,
      muted: !!widget?.socialTiktok?.muted || this.settings.defaultSlideData.muted,
      maxTimeMsPerVideo: widget?.socialTiktok?.maxTimeMsPerVideo || this.settings.defaultSlideData.maxTimeMsPerVideo
    }
  }

  getDescription = () => {
    const hashtags = this.slideData.hashtags || []
    const mentions = this.slideData.mentions || []
    return [...hashtags.map(h=>'#'+h), ...mentions.map(m=>'@'+m)]
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/tiktok.png')
  }

}

export class SocialActivitySlide extends Slide {
  static icon = 'heart-outlined'
  static nameKey = 'socialActivity'
  static type = 'SocialActivitySlide'
  settings = {
    defaultSlideData: {
      socialInstagramAccountIds: [],
      socialTwitterAccountIds: [],
      feedType: 'TOP',
      hashtags: [],
      includeOwnActivity: false,
      muted: false,
      durationPerItem: DEFAULT_DURATION_PER_SLIDE_ITEM
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'SOCIAL_ACTIVITY',
      dbType: 'widgetSocialActivity',
      isMutable: true,
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.socialActivity
      ? {
        socialInstagramAccountIds: widget?.socialActivity?.socialInstagramAccountIds,
        socialTwitterAccountIds: widget?.socialActivity?.socialTwitterAccountIds,
        hashtags: widget?.socialActivity?.hashtags,
        feedType: widget?.socialActivity?.feedType,
        includeOwnActivity: widget?.socialActivity?.includeOwnActivity,
        muted: !!widget?.socialActivity?.muted || this.settings.defaultSlideData.muted,
        durationPerItem: widget?.socialActivity?.durationPerItem || this.settings.defaultSlideData.duration
      }
      : this.settings.defaultSlideData
  }

  getDescription = () => {
    return this.slideData.hashtags?.length > 0 ? [...this.slideData.includeOwnActivity ? ['+ Own posts'] : [], ...this.slideData.hashtags?.map(h => h.replace('#', ''))] : 'Showing own posts'
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/social-activity.png')
  }

}

class SmartTemplateSlide extends Slide {
  static icon = 'experiment-outlined'
  static nameKey = 'smartTemplate'
  static type = 'SmartTemplateSlide'
  settings
  slideData
  logoMedia = ref(null)
  imageMedia = ref(null)
  backgroundMedia = ref(null)

  constructor (slide, {
    widgetType,
    dbType,
    settings
  }) {
    super({
      slide,
      widgetType,
      dbType
    })
    this.settings = settings
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = widget?.smartTemplate || this.settings.defaultSlideData
    this.#initBackground()
  }

  #initBackground = () => {
    const background = this.widget?.backgroundMedia
    if (!background) return
    this.backgroundMedia.value = new SlideAsset({
      id: background.id,
      sourceType: background.sourceType,
      metadata: background.metadata,
      thumbnail: getThumbnailFromRawAsset(background),
      source: getSourceFromRawAsset(background),
      name: background.name || background.dropboxAdditionalInfo?.name || background.gDriveAdditionalInfo?.name,
      mediaReference: this.slideData.background?.mediaVideoReference || this.slideData.background?.mediaImageReference
    })
  }

  setSlideData = (data) => {
    this.slideData = data
  }

  getAssets = () => {
    return {
      background: this.backgroundMedia?.value,
      logo: this.logoMedia?.value,
      image: this.imageMedia?.value
    }
  }

}

export class SmartTemplateTextSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Simple Sign'
  static template = 'SimpleSignTemplate'
  static editor = 'SimpleSignEditor'
  static preview = require('@/assets/images/smart-templates/text.png')
  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_TEXT',
        dbType: 'widgetSmartTemplateText',
        settings: {
          defaultSlideData: {
            foreground: '#ffffff',
            background: {
              animation: 'triangles'
            },
            layout: 'center',
            caption: 'You need to check this out',
            header: 'The very best simple\npromo is here',
            subHeader: 'Add all needed descriptions and\nlet your business grow.'
          }
        }
      }
    )
  }

  getDescription = () => {
    return t('slideDescriptions.simpleText') || 'Simple Text'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }

}

export class SmartTemplateSaleSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Sale'
  static template = 'SaleTemplate'
  static editor = 'SaleEditor'
  static animations = ['memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/sale.png')
  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_SALE',
        dbType: 'widgetSmartTemplateSale',
        settings: {
          defaultSlideData: {
            foreground: '#000000',
            background: {
              animation: 'circles'
            },
            layout: 'center',
            caption: 'We offer you today',
            discount: '70',
            header: 'On All Summer Clothes!',
            description: 'Hurry up – special deal ends',
          }
        }
      }
    )
  }

  getDescription = () => {
    return t('slideDescriptions.sale') || 'Sale'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class SmartTemplateScheduleSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Working Hours'
  static template = 'WorkingHoursTemplate'
  static editor = 'WorkingHoursEditor'
  static animations = ['clock', 'memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/schedule.png')
  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_SCHEDULE',
        dbType: 'widgetSmartTemplateSchedule',
        settings: {
          defaultSlideData: {
            foreground: '#ffffff',
            background: {
              animation: 'clock'
            },
            header: 'We Are Open For Service:',
            workingHours: [{
              day: 'Mon-Fri',
              time: '10AM-6PM'
            },
              {
                day: 'Mon-Fri',
                time: '10AM-6PM'
              }]
          },
        }
      }
    )
  }

  getDescription = () => {
    return t('slideDescriptions.schedule') || 'Schedule'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class SmartTemplateDirectorySlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Directory'
  static template = 'DirectoryTemplate'
  static editor = 'DirectoryEditor'
  static animations = ['memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/directory.png')
  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_DIRECTORY',
        dbType: 'widgetSmartTemplateDirectory',
        settings: {
          defaultSlideData: {
            foreground: '#ffffff',
            background: {
              animation: 'circles'
            },
            directories: [{
              number: '1',
              title: 'Studio Hiphen',
              description: 'Please come and visit us today!',
              direction: 0
            }]
          }
        }
      }
    )
  }

  getDescription = () => {
    return t('slideDescriptions.directory') || 'Directory'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class SmartTemplateFacebookSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Facebook Group'
  static template = 'FacebookGroupTemplate'
  static editor = 'FacebookGroupEditor'
  static animations = ['memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/fb-group.png')
  logoMedia = ref(null)

  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_FACEBOOK',
        dbType: 'widgetSmartTemplateFacebook',
        settings: {
          defaultSlideData: {
            foreground: '#000000',
            background: {
              animation: 'circles_triangles'
            },
            layout: 'center',
            address: ''
          }
        }
      }
    )
    this.#initLogo()
  }

  #initLogo = () => {
    const logo = this.widget?.logoMediaImage
    if (!logo) return
    this.logoMedia.value = new SlideAsset({
      id: logo.id,
      sourceType: logo.sourceType,
      metadata: logo.metadata,
      thumbnail: getThumbnailFromRawAsset(logo),
      source: getSourceFromRawAsset(logo),
      name: logo.name || logo.dropboxAdditionalInfo?.name || logo.gDriveAdditionalInfo?.name,
      mediaReference: this.slideData.logoMediaImageReference
    })
  }

  getDescription = () => {
    return t('slideDescriptions.facebookGroup') || 'Facebook Group'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class SmartTemplateFloorMapSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Floor Map'
  static template = 'FloorMapTemplate'
  static editor = 'FloorMapEditor'
  static animations = ['memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/floor-map.png')
  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_FLOOR_MAP',
        dbType: 'widgetSmartTemplateFloorMap',
        settings: {
          defaultSlideData: {
            foreground: '#000000',
            background: {
              animation: 'circles'
            },
            floor: '01',
            sections: 'Shoes\nGrocery\nJewelry\nRestaurants\nATM',
          }
        }
      }
    )
  }

  getDescription = () => {
    return t('slideDescriptions.floorMap') || 'Floor Map'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class SmartTemplateMenuSlide extends SmartTemplateSlide {
  static getTemplateName = () => 'Menu'
  static template = 'MenuTemplate'
  static editor = 'MenuEditor'
  static animations = ['memphis', 'memphis2']
  static preview = require('@/assets/images/smart-templates/menu.png')
  imageMedia = ref(null)

  constructor (slide) {
    super(
      slide,
      {
        widgetType: 'SMART_TEMPLATE_MENU',
        dbType: 'widgetSmartTemplateMenu',
        settings: {
          defaultSlideData: {
            foreground: '#ffffff',
            background: {
              color: '#000000'
            },
            caption: '“Eleanor’s place”',
            header: 'Sweet Deal!\nCheck this out:',
            subHeader: 'Amazing fruits',
            price: '$12.99',
            comment: 'Please note prices do not include\nNYC Sales Tax or Gratuity Reservation only.',
            mediaImageReference: null,
          }
        }
      }
    )
    this.#initImage()
  }

  #initImage = () => {
    const image = this.widget?.mediaImage
    if (!image) return
    this.imageMedia.value = new SlideAsset({
      id: image.id,
      sourceType: image.sourceType,
      metadata: image.metadata,
      thumbnail: getThumbnailFromRawAsset(image),
      source: getSourceFromRawAsset(image),
      name: image.name || image.dropboxAdditionalInfo?.name || image.gDriveAdditionalInfo?.name,
      mediaReference: this.slideData.mediaImageReference
    })
  }

  getDescription = () => {
    return t('slideDescriptions.menu') || 'Menu'
  }

  getPreviewUrl = () => {
    return this.constructor.preview
  }
}

export class TickerSlide extends Slide {
  static icon = 'stock-outlined'
  static nameKey = 'ticker'
  static type = 'TickerSlide'
  settings = {
    defaultSlideData: {
      tickers: []
    }
  }
  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'TICKER',
      dbType: 'widgetTicker'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      tickers: widget?.data?.tickers || this.settings.defaultSlideData.tickers
    }
  }

  getDescription = () => {
    return this.slideData.tickers?.map(t => t.symbol)
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/ticker.png')
  }

}

export class FunFactsSlide extends Slide {
  static icon = 'read-outlined'
  static nameKey = 'funFacts'
  static type = 'FunFactsSlide'

  constructor (slide) {
    super({
      slide,
      widgetType: 'RAPID_API_FUN_FACTS',
      dbType: 'widgetRapidApiFunFacts'
    })
  }

  getDescription = () => {
    return t(`components.slides.funFacts.description`)
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/facts.png')
  }

}

export class HoroscopesSlide extends Slide {
  static icon = 'star-outlined'
  static nameKey = 'horoscopes'
  static type = 'HoroscopesSlide'
  settings = {
    defaultSlideData: {
      signs: ['aries'],
      period: 'today',
      horoscopeType: 'general'
    }
  }

  constructor (slide) {
    super({
      slide,
      widgetType: 'RAPID_API_HOROSCOPES',
      dbType: 'widgetRapidApiHoroscopes'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      signs: widget?.data?.signs || this.settings.defaultSlideData.signs,
      period: widget?.data?.period || this.settings.defaultSlideData.period,
      horoscopeType: widget?.data?.horoscopeType || this.settings.defaultSlideData.horoscopeType
    }
  }

  getDescription = () => {
    if (this.slideData?.signs?.length === 12) return ['All']
    return this.slideData?.signs || []
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/horoscope.png')
  }

}

export class LiveScoreSlide extends Slide {
  static icon = 'trophy-outlined'
  static nameKey = 'liveScore'
  static type = 'LiveScoreSlide'
  slideData = {}

  constructor (slide) {
    super({
      slide,
      widgetType: 'RAPID_API_LIVE_SCORE',
      dbType: 'widgetLiveScore'
    })
  }

  getDescription = () => {
    return t(`components.slides.liveScore.description`)
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/sport-news.png')
  }

}

export class PublicHolidaysSlide extends Slide {
  static icon = 'smile-outlined'
  static nameKey = 'publicHolidays'
  static type = 'PublicHolidaysSlide'
  settings = {
    defaultSlideData: {
      country: null
    }
  }

  slideData

  constructor (slide) {
    super({
      slide,
      widgetType: 'RAPID_API_PUBLIC_HOLIDAYS',
      dbType: 'widgetRapidApiPublicHolidays'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      country: widget?.data?.country || this.settings.defaultSlideData.country
    }
  }

  getDescription = () => {
    return this.slideData?.country ? countriesMap[this.slideData.country] : ''
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/holidays.png')
  }

}

export class TEDTalksSlide extends Slide {
  static icon = 'user-outlined'
  static nameKey = 'tedTalks'
  static type = 'TEDTalksSlide'
  settings = {
    defaultSlideData: {
      tedTalkId: null,
      muted: false,
      objectFit: 'FIT',
      continueVideoOnRestarts: true
    }
  }
  slideData = {}
  tedTalk = {}

  constructor (slide) {
    super({
      slide,
      widgetType: 'RAPID_API_TED_TALKS',
      isMutable: true,
      dbType: 'widgetRapidApiTedTalks',
      hasObjectFit: true
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      tedTalkId: widget?.data?.tedTalkId || this.settings.defaultSlideData.tedTalkId,
      muted: widget?.data?.muted || this.settings.defaultSlideData.muted,
      objectFit: widget?.data?.objectFit || this.settings.defaultSlideData.objectFit,
      continueVideoOnRestarts: widget ? widget?.data?.continueVideoOnRestarts : this.settings.defaultSlideData.continueVideoOnRestarts
    }
    this.tedTalk = widget?.tedTalk
  }

  getDescription = () => {
    return this.tedTalk?.title
  }

  getPreviewUrl = () => {
    return this.tedTalk?.thumbnail_url || require('@/assets/images/slides/ted.png')
  }

}

export class TastySlide extends Slide {
  static icon = 'coffee-outlined'
  static nameKey = 'tasty'
  static type = 'TastySlide'
  settings = {
    defaultSlideData: {
      objectFit: 'FIT',
      tags: [],
      continueVideoOnRestarts: true,
      muted: false
    }
  }
  slideData = {}
  tastyTags = null

  constructor (slide) {
    super({
      slide,
      isMutable: true,
      widgetType: 'RAPID_API_TASTY',
      hasObjectFit: true,
      dbType: 'widgetRapidApiTasty'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    this.slideData = {
      tags: widget?.data?.tags || this.settings.defaultSlideData.tags,
      muted: widget?.data?.muted || this.settings.defaultSlideData.muted,
      objectFit: widget?.data?.objectFit || this.settings.defaultSlideData.objectFit,
      continueVideoOnRestarts: widget ? widget?.data?.continueVideoOnRestarts : this.settings.defaultSlideData.continueVideoOnRestarts
    }

    this.tastyTags = widget?.tags

  }

  getDescription = () => {
    return this.tastyTags?.map(t => t.display_name) || []
  }

  getPreviewUrl = () => {
    return require('@/assets/images/slides/tasty.png')
  }
}

export class CustomSlide extends Slide {
  static icon = 'bg-colors-outlined'
  static nameKey = 'custom'
  static type = 'CustomSlide'
  imageMedia = null
  hasFullPreviewUrl = true

  settings = {
    defaultSlideData: {
      json: {},
      imageBase64: null,
      aspectRatio: null
    }
  }

  constructor (slide) {
    super({
      slide,
      widgetType: 'IMAGE_COMPOSER',
      dbType: 'widgetImageComposer'
    })
    const widget = slide?.widget ? stripObjTypename(slide.widget) : null
    const ar =  widget?.data?.aspectRatio || this.settings.defaultSlideData.aspectRatio
    this.slideData = {...this.settings.defaultSlideData, aspectRatio: ar }
    this.imageMedia = widget?.imageMedia
  }

  getDescription = () => {
    return t('components.slides.custom.description')
  }

  updateAspectRatio = (ar) => {
    this.slideData.aspectRatio = ar
  }

  getPreviewUrl = () => {
    return getThumbnailFromRawAsset(this.imageMedia) || require('@/assets/images/slides/image.png') || ''
  }

  getFullPreviewUrl = () => {
    if (!this.hasFullPreviewUrl) return null
    return getSourceFromRawAsset(this.imageMedia)

  }

}

const getPreviewFromRawAsset = (asset) => {
  return asset.generatedMedia?.find(m => m.tag === 'preview')?.url
}

const getThumbnailFromRawAsset = (asset) => {
  return asset.generatedMedia?.find(m => m.tag === 'thumbnail')?.url
}
const getSourceFromRawAsset = (asset) => {
  return asset.generatedMedia?.find(m => m.tag === 'content')?.url || asset.generatedMedia.find(m => m.tag === 'web')?.url
}

export const slideTypes = {
  'MEDIA_IMAGES': ImageSlide,
  'MEDIA_VIDEO': VideoSlide,
  'MEDIA_DOCUMENT': DocumentSlide,
  'STREAM': StreamSlide,
  'CALENDAR_GOOGLE': GoogleCalendarSlide,
  'CALENDAR_MICROSOFT': MicrosoftCalendarSlide,
  'WEBSITE': WebsiteSlide,
  'AI_CONTENT': AIContentSlide,
  'WEATHER': WeatherSlide,
  'WORLD_CLOCK': WorldClockSlide,
  'QUOTES': QuotesSlide,
  'NEWS': NewsSlide,
  'FEED': FeedSlide,
  'YELP': YelpSlide,
  'TRIP_ADVISOR': TripAdvisorSlide,
  'IMAGE_COMPOSER': CustomSlide,
  'GOOGLE_REVIEWS': GoogleReviewsSlide,
  'SOCIAL_INSTAGRAM': SocialInstagramSlide,
  'SOCIAL_ACTIVITY': SocialActivitySlide,
  'SOCIAL_TWITTER': SocialTwitterSlide,
  'SOCIAL_TIKTOK': SocialTiktokSlide,
  'SMART_TEMPLATE_TEXT': SmartTemplateTextSlide,
  'SMART_TEMPLATE_SALE': SmartTemplateSaleSlide,
  'SMART_TEMPLATE_SCHEDULE': SmartTemplateScheduleSlide,
  'SMART_TEMPLATE_DIRECTORY': SmartTemplateDirectorySlide,
  'SMART_TEMPLATE_FACEBOOK': SmartTemplateFacebookSlide,
  'SMART_TEMPLATE_FLOOR_MAP': SmartTemplateFloorMapSlide,
  'SMART_TEMPLATE_MENU': SmartTemplateMenuSlide,
  'TICKER': TickerSlide,
  'RAPID_API_FUN_FACTS': FunFactsSlide,
  'RAPID_API_HOROSCOPES': HoroscopesSlide,
  'RAPID_API_LIVE_SCORE': LiveScoreSlide,
  'RAPID_API_PUBLIC_HOLIDAYS': PublicHolidaysSlide,
  'RAPID_API_TED_TALKS': TEDTalksSlide,
  'RAPID_API_TASTY': TastySlide,
}

const WIDGET_TYPE_LIST_MENU_KEYS = [
  'MEDIA_IMAGES',
  'MEDIA_VIDEO',
  'MEDIA_DOCUMENT',
  'STREAM',
  'WEBSITE',
  'SMART_TEMPLATE_SALE',
  'IMAGE_COMPOSER',
  'AI_CONTENT',
  'CALENDAR_GOOGLE',
  'CALENDAR_MICROSOFT',
  'SOCIAL_TIKTOK',
  'SOCIAL_INSTAGRAM',
  'SOCIAL_TWITTER',
  'SOCIAL_ACTIVITY',
  'RAPID_API_TASTY',
  'WEATHER',
  'WORLD_CLOCK',
  'NEWS',
  'FEED',
  'RAPID_API_TED_TALKS',
  // 'GOOGLE_REVIEWS',
  // 'TRIP_ADVISOR',
  'YELP',
  'QUOTES',
  // 'RAPID_API_FUN_FACTS',
  // 'RAPID_API_HOROSCOPES',
  // 'RAPID_API_LIVE_SCORE',
  // 'RAPID_API_PUBLIC_HOLIDAYS',
  // 'TICKER'
]

export const DISABLED_WIDGETS = process.env.VUE_APP_DISABLED_WIDGET_MENU_ITEMS?.replace(/ /g, '')?.split(',') || []

export const SMART_TEMPLATE_WIDGET_TYPES = [
  'SMART_TEMPLATE_SALE',
  'SMART_TEMPLATE_TEXT',
  'SMART_TEMPLATE_MENU',
  'SMART_TEMPLATE_SCHEDULE',
  'SMART_TEMPLATE_DIRECTORY',
  'SMART_TEMPLATE_FACEBOOK',
  'SMART_TEMPLATE_FLOOR_MAP'
]

export const DISABLED_WIDGETS_TYPES = DISABLED_WIDGETS.map(t => slideTypes[t]?.type)

export const WIDGET_TYPE_LIST_MENU_ITEMS = WIDGET_TYPE_LIST_MENU_KEYS.map(t => {
  return {
    name: slideTypes[t].nameKey,
    id: slideTypes[t].type,
    icon: slideTypes[t].icon
  }
})

export const TEMPLATE_MENU = SMART_TEMPLATE_WIDGET_TYPES.map(t=>{
  return {
    type: t,
    name: slideTypes[t].getTemplateName(),
    preview: slideTypes[t].preview
  }
})

export const getSocialHashtagsOrMentionsOnChange = (value = []) => {
  return value.map(v=>v.replace(/#|@|\s/gi, '').toLowerCase())
}





