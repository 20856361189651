<script setup>
import InfoHeader from '@/components/InfoHeader.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import moment from 'moment'
const store = useStore()

const openUpgradeSubscriptionModal = () => {
  saveCouponCodeToLocalStorage()
  store.dispatch('handleUpdateSubscription')
}

const couponCode = 'NEW20'

const saveCouponCodeToLocalStorage = () => {
  localStorage.setItem('couponCode', couponCode)
}

</script>

<template>
  <InfoHeader
    type="black"
  >
    <a-space>
      <strong>{{ $t('components.firstPurchaseHeader.text') }}</strong>
      <a-button
        type="default"
        size="small"
        @click="openUpgradeSubscriptionModal"
      >
        {{ $t('components.firstPurchaseHeader.ctaButtonText') }}
      </a-button>
    </a-space>
  </InfoHeader>
</template>

<style scoped lang="less">

</style>
