import ApolloClient from '@/apollo'
import {
  GET_CURRENT_WORKSPACE,
  GET_CURRENT_WORKSPACE_BILLING_INFO,
  GET_CURRENT_WORKSPACE_BILLING_INFO_AND_FROZEN_STATUSES,
  GET_CURRENT_WORKSPACE_EDU_TIER_APPLICATION,
  GET_CURRENT_WORKSPACE_LOGO_AND_FAVICON,
  GET_CURRENT_WORKSPACE_WATERMARK,
  GET_WORKSPACE_ACTIVITY_LOGS,
  GET_WORKSPACE_MEMBERS,
  GET_WORKSPACE_PENDING_INVITE_LINK,
  GET_WORKSPACE_PENDING_INVITES,
  GET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  GET_WORKSPACES
} from '@/graphql/queries'
import {
  CANCEL_INVITE,
  CREATE_WORKSPACE,
  DELETE_WORKSPACE_FAVICON,
  DELETE_WORKSPACE_LOGO,
  DELETE_WORKSPACE_WATERMARK,
  REMOVE_WORKSPACE_MEMBER_BY_ID, RESEND_INVITE,
  SEND_INVITE,
  SET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  UNSET_WORKSPACE_SSO_IDENTITY_PROVIDER,
  UPDATE_WORKSPACE_BY_ID, UPDATE_WORKSPACE_MEMBER_BY_ID,
  UPDATE_WORKSPACE_NOTIFICATION_SETTINGS,
  UPLOAD_WORKSPACE_FAVICON,
  UPLOAD_WORKSPACE_LOGO,
  UPLOAD_WORKSPACE_WATERMARK
} from '@/graphql/mutations'
import router from '@/router'
import { formatFileSize } from '@/utils'
import { handleError } from '@/helpers/ErrorHandler'
import moment from 'moment/moment'
import { ROLES } from '@/constants'

const TRIAL_TIERS = ['business-trial', 'education-trial']
const BUSINESS_GROWTH_TIER = 'business-growth'
const EDUCATION_GROWTH_TIER = 'education-growth'
const EDUCATION_STARTER_TIER = 'education-starter'

const isTierTrial = tier => TRIAL_TIERS.includes(tier)

export default {
  namespaced: true,
  state: {
    workspace: null,
    workspacesList: [],
    workspaceWatermarkLoadingStatus: false,
    workspaceLogoLoadingStatus: false,
    workspaceFaviconLoadingStatus: false,
    workspaceLogoFaviconSub: null,
    workspaceWatermarkSub: null,
    workspaceMembers: [],
    workspacePendingInvites: [],
    workspaceActivityLogs: null,
    workspaceActivityLogsLoading: false,
    workspaceLoaded: false,
    workspaceSSOIdentityProvider: null,
    changingWorkspace: false
  },
  actions: {
    async getWorkspaceInfo ({ commit, state }) {
      state.workspaceLoaded = false
      try {
        const { data: { getCurrentWorkspace: workspace } } = await ApolloClient.query({ query: GET_CURRENT_WORKSPACE, fetchPolicy: 'no-cache' })
        await commit('SET_CURRENT_WORKSPACE', { workspace })
        state.workspaceLoaded = true
      } catch (e) {
        console.log(e)
      }
    },
    async getWorkspaceActivityLogs ({ commit, state }, {pagination = { limit: 10}, filters}) {
      const { offset } = pagination
      state.workspaceActivityLogsLoading = true
      try {
        const { data: { listActivityLogs: { data, pagination: _pagination } } } = await ApolloClient.query({ query: GET_WORKSPACE_ACTIVITY_LOGS, fetchPolicy: 'no-cache', variables: {pagination} })
        if (offset) {
          commit('ADD_WORKSPACE_ACTIVITY_LOGS', { data, pagination: _pagination })
        }
        else {
          commit('SET_WORKSPACE_ACTIVITY_LOGS', { data, pagination: _pagination })
        }
        state.workspaceActivityLogsLoading = false
      } catch (e) {
        console.log(e)
        state.workspaceActivityLogsLoading = false
      }
    },
    async updateBillingInfo ({ commit }) {
      try {
        const { data: { getCurrentWorkspace: workspace } } = await ApolloClient.query({ query: GET_CURRENT_WORKSPACE_BILLING_INFO, fetchPolicy: 'no-cache' })
        commit('UPDATE_CURRENT_WORKSPACE', { billingInfo: workspace.billingInfo })
      } catch (e) {
        console.log(e)
      }
    },
    async updateFrozenStatusesAndBillingInfo ({ commit }) {
      try {
        const { data: { getCurrentWorkspace: workspace } } = await ApolloClient.query({ query: GET_CURRENT_WORKSPACE_BILLING_INFO_AND_FROZEN_STATUSES, fetchPolicy: 'no-cache' })
        commit('UPDATE_CURRENT_WORKSPACE', { billingInfo: workspace.billingInfo, frozen: workspace.frozen, frozenReason: workspace.frozenReason })
      } catch (e) {
        console.log(e)
      }
    },
    async updateEduTierApplication ({ commit }) {
      try {
        const { data: { getCurrentWorkspace: workspace } } = await ApolloClient.query({ query: GET_CURRENT_WORKSPACE_EDU_TIER_APPLICATION, fetchPolicy: 'no-cache' })
        commit('UPDATE_CURRENT_WORKSPACE', { eduTierApplication: workspace.eduTierApplication })
      } catch (e) {
        console.log(e)
      }
    },
    async getAvailableWorkspaces ({ commit }) {
      try {
        const { data: { listMyWorkspaces } } = await ApolloClient.query({ query: GET_WORKSPACES, fetchPolicy: 'no-cache' })
        await commit('SET_AVAILABLE_WORKSPACES', listMyWorkspaces)
      } catch (e) {
        console.log(e)
      }
    },
    async getWorkspaceMembers ({ commit }) {
      try {
        const { data: { listCurrentWorkspaceMemberRoles } } = await ApolloClient.query({ query: GET_WORKSPACE_MEMBERS, fetchPolicy: 'no-cache' })
        commit('SET_WORKSPACE_MEMBERS', listCurrentWorkspaceMemberRoles)
      } catch (e) {
        console.log(e)
      }
    },
    async removeWorkspaceMember ({ commit }, userId) {
      await ApolloClient.mutate({ mutation: REMOVE_WORKSPACE_MEMBER_BY_ID, variables: { userId } })
      commit('REMOVE_WORKSPACE_MEMBER_BY_ID', userId)
    },
    async updateWorkspaceMember ({ commit }, { userId, role, restrictions }) {
      try {
        const {data: { updateCurrentWorkspaceMemberRole } } = await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_MEMBER_BY_ID, variables: { input: { userId, role, restrictions } } })
        commit('UPDATE_WORKSPACE_MEMBER_BY_ID', updateCurrentWorkspaceMemberRole)
      }
      catch (e) {
        throw e
      }
    },
    async createAndChangeWorkspace ({ dispatch }, input) {
      try {
        const { data: { createWorkspace: { id } } } = await ApolloClient.mutate({
          mutation: CREATE_WORKSPACE,
          variables: { input }
        })
        dispatch('changeWorkspace', id)
      }
      catch (e) {
        console.log(e)
      }
    },
    async changeWorkspace ({dispatch, commit, state, getters, rootGetters}, workspaceId) {
      if (getters.workspaceId === workspaceId) return
      state.changingWorkspace = true
      await dispatch('resetWorkspace')
      await dispatch('getAvailableWorkspaces')
      await dispatch('auth/setWorkspaceForSession', { forcedWorkspaceId: workspaceId }, {root: true})
      await dispatch('setWorkspace')
      await dispatch('groups/getAvailableStandardGroups', null, {root: true})
      await dispatch('groups/getAvailableSmartGroups', !rootGetters['workspace/smartGroupsEnabled'] ? true : null, {root: true})
      router.replace({name: 'Login'}).then(()=>{
        state.changingWorkspace = false
      })
    },
    async setWorkspace ({dispatch, getters}) {
      await dispatch('getWorkspaceInfo')
      if (getters.isOwner || getters.isAdmin) {
        dispatch('subscription/getWorkspaceSubscription', null, {root: true})
      }
      await dispatch('social/getSocialAccounts', null, { root: true })
      if (getters.isOwner || getters.isAdmin) {
        await dispatch('workspace/getWorkspaceMembers', null, { root: true })
        await dispatch('workspace/getWorkspacePendingInvites', null, { root: true })
      }
    },
    async handleEDU ({getters, rootGetters, dispatch}) {
      const userIsComplete = rootGetters['auth/userIsComplete']
      const { applied, available, shown } = getters.eduTierApplication
      if (!applied && !shown && available && userIsComplete) {
        dispatch('openEDUAppliance', null, { root: true })
      }
    },
    async updateEDUAppliance ({dispatch}, input) {
      return dispatch('updateWorkspace', {eduTierApplication: input})
    },
    async resetWorkspace ({commit, dispatch}) {
      await commit('CLEAR_CONFIG', null, { root: true })
      await commit('CLEAR_PREV_ROUTE', null, { root: true })
      await commit('workspace/CLEAR_WORKSPACE_DATA', null, { root: true })
      await commit('groups/CLEAR_GROUPS_DATA', null, { root: true })
      await commit('subscription/CLEAR_SUBSCRIPTION_DATA', null, { root: true })
      await commit('media/CLEAR_MEDIA_DATA', null, { root: true })
      await commit('playlist/CLEAR_PLAYLIST_DATA', null, { root: true })
      await commit('devices/CLEAR_DEVICES_DATA', null, { root: true })
      await commit('notifications/CLEAR_NOTIFICATIONS', null, { root: true })
      await commit('tags/CLEAR_TAGS_DATA', null, { root: true })
      await dispatch('closeAllModals', null, { root: true })
      commit('CLEAR_WORKSPACE_DATA')
    },
    async getWorkspaceSSOIdentityProvider ({ commit }) {
      try {
        const { data: { getSsoIdentityProvider } } = await ApolloClient.query({ query: GET_WORKSPACE_SSO_IDENTITY_PROVIDER, fetchPolicy: 'no-cache' })
        commit('SET_SSO_IDENTITY_PROVIDER', getSsoIdentityProvider)
      } catch (e) {
        handleError(e)
      }
    },
    async setWorkspaceSSOIdentityProvider ({ commit }, { clientId, clientSecret, issuer }) {
      try {
        const { data: { setSsoIdentityProviderOidc } } = await ApolloClient.mutate({ mutation: SET_WORKSPACE_SSO_IDENTITY_PROVIDER, variables: { input: {clientId, clientSecret, issuer} }})
        commit('SET_SSO_IDENTITY_PROVIDER', setSsoIdentityProviderOidc)
      } catch (e) {
        handleError(e)
      }
    },
    async unsetWorkspaceSSOIdentityProvider ({ commit }) {
      try {
        await ApolloClient.mutate({ mutation: UNSET_WORKSPACE_SSO_IDENTITY_PROVIDER })
        commit('UNSET_SSO_IDENTITY_PROVIDER')
      } catch (e) {
        handleError(e)
      }
    },
    async getWorkspacePendingInvites ({ commit }) {
      try {
        const { data: { listCurrentWorkspacePendingInvites } } = await ApolloClient.query({ query: GET_WORKSPACE_PENDING_INVITES, fetchPolicy: 'no-cache' })
        commit('SET_WORKSPACE_PENDING_INVITES', listCurrentWorkspacePendingInvites)
      } catch (e) {
        handleError(e)
      }
    },
    async sendInvite ({ commit }, payload) {
      try {
        const { data: { sendInvite } } = await ApolloClient.mutate({ mutation: SEND_INVITE, variables: { input: payload } })
        commit('ADD_WORKSPACE_PENDING_INVITE', sendInvite)
      } catch (e) {
        throw new Error(e.graphQLErrors?.[0].extensions?.code)
      }
    },
    async resendInvite ({}, inviteId) {
      return ApolloClient.mutate({ mutation: RESEND_INVITE, variables: { inviteId } })
    },
    async cancelInvite ({ commit }, email) {
      try {
        await ApolloClient.mutate({ mutation: CANCEL_INVITE, variables: { email } })
        commit('REMOVE_WORKSPACE_PENDING_INVITE', email)
      } catch (e) {
        handleError(e)
      }
    },
    async getInviteLink ({ commit }, inviteId) {
      try {
        const {data: {getCurrentWorkspacePendingInviteLink}} = await ApolloClient.query({ query: GET_WORKSPACE_PENDING_INVITE_LINK, variables: { inviteId }, fetchPolicy: 'no-cache' })
        return getCurrentWorkspacePendingInviteLink
      } catch (e) {
        handleError(e)
      }
    },
    async updateWorkspaceNotificationSettings ({ commit }, settings) {
      try {
        const { data: { updateMyCurrentWorkspaceMemberRoleSettings } } = await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_NOTIFICATION_SETTINGS, variables: { input: settings } })
        commit('UPDATE_CURRENT_WORKSPACE_MEMBER_ROLE', updateMyCurrentWorkspaceMemberRoleSettings)
      } catch (e) {
        console.log(e)
      }
    },
    async updateWorkspace ({ commit, getters }, input) {
      const id = getters.workspaceId
      try {
        const { data: { updateWorkspaceById } } = await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_BY_ID, variables: { id, input } })
        commit('UPDATE_CURRENT_WORKSPACE', updateWorkspaceById)
      } catch (e) {
        console.log(e)
      }
    },
    async uploadWorkspaceWatermark ({ commit, dispatch, getters }, file) {
      commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        const { data: { uploadWorkspaceWatermark } } = await ApolloClient.mutate({ mutation: UPLOAD_WORKSPACE_WATERMARK, variables: { workspaceId, file }, context: { hasUpload: true } })
        dispatch('fetchWorkspaceWatermark')
        return uploadWorkspaceWatermark
      } catch (e) {
        commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async deleteWorkspaceWatermark ({ commit, getters }) {
      commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        await ApolloClient.mutate({ mutation: DELETE_WORKSPACE_WATERMARK, variables: { workspaceId } })
        commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', false)
        commit('UPDATE_CURRENT_WORKSPACE', { watermarkMedia: [] })
      } catch (e) {
        commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async uploadWorkspaceLogo ({ commit, dispatch, getters }, file) {
      commit('SET_WORKSPACE_LOGO_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        const { data: { uploadWorkspaceWhitelabelLogo } } = await ApolloClient.mutate({ mutation: UPLOAD_WORKSPACE_LOGO, variables: { workspaceId, file }, context: { hasUpload: true } })
        dispatch('fetchWorkspaceLogoAndFavicon')
        return uploadWorkspaceWhitelabelLogo
      } catch (e) {
        commit('SET_WORKSPACE_LOGO_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async deleteWorkspaceLogo ({ commit, getters }) {
      commit('SET_WORKSPACE_LOGO_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        await ApolloClient.mutate({ mutation: DELETE_WORKSPACE_LOGO, variables: { workspaceId } })
        commit('SET_WORKSPACE_LOGO_LOADING_STATUS', false)
        commit('UPDATE_CURRENT_WORKSPACE', { whitelabelLogoMedia: [] })
        commit('auth/REMOVE_LOGO', null, { root: true })
      } catch (e) {
        commit('SET_WORKSPACE_LOGO_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async uploadWorkspaceFavicon ({ commit, dispatch, getters }, file) {
      commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        const { data: { uploadWorkspaceWhitelabelFavicon } } = await ApolloClient.mutate({ mutation: UPLOAD_WORKSPACE_FAVICON, variables: { workspaceId, file }, context: { hasUpload: true } })
        dispatch('fetchWorkspaceLogoAndFavicon')
        return uploadWorkspaceWhitelabelFavicon
      } catch (e) {
        commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async deleteWorkspaceFavicon ({ commit, getters }) {
      commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', true)
      const workspaceId = getters.workspaceId
      try {
        await ApolloClient.mutate({ mutation: DELETE_WORKSPACE_FAVICON, variables: { workspaceId } })
        commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', false)
        commit('UPDATE_CURRENT_WORKSPACE', { whitelabelFaviconMedia: [] })
        commit('auth/REMOVE_FAVICON', null, { root: true })
      } catch (e) {
        commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', false)
        handleError(e)
      }
    },
    async fetchWorkspaceLogoAndFavicon ({ commit, state }) {
      if (state.workspaceLogoFaviconSub) {
        return
      }
      const pollInterval = 1500
      state.workspaceLogoFaviconSub = ApolloClient.watchQuery({ query: GET_CURRENT_WORKSPACE_LOGO_AND_FAVICON, pollInterval, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getCurrentWorkspace: { whitelabelFaviconMedia, whitelabelLogoMedia } }, loading }) => {
          const logoLoaded = whitelabelLogoMedia?.processingStatus === 'FINISHED' && !!whitelabelLogoMedia?.generatedMedia?.length
          const faviconLoaded = whitelabelFaviconMedia?.processingStatus === 'FINISHED'
          if (logoLoaded) {
            commit('SET_WORKSPACE_LOGO_LOADING_STATUS', false)
            commit('UPDATE_CURRENT_WORKSPACE', { whitelabelLogoMedia })
          }
          if (faviconLoaded) {
            commit('SET_WORKSPACE_FAVICON_LOADING_STATUS', false)
            commit('UPDATE_CURRENT_WORKSPACE', { whitelabelFaviconMedia })
          }
          if (logoLoaded && faviconLoaded) {
            state.workspaceLogoFaviconSub.unsubscribe()
            state.workspaceLogoFaviconSub = null
          }
        })
    },
    async fetchWorkspaceWatermark ({ commit, state }) {
      if (state.workspaceWatermarkSub) {
        return
      }
      const pollInterval = 1500
      state.workspaceWatermarkSub = ApolloClient.watchQuery({ query: GET_CURRENT_WORKSPACE_WATERMARK, pollInterval, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getCurrentWorkspace: { watermarkMedia } }, loading }) => {
          const watermarkLoaded = watermarkMedia?.processingStatus === 'FINISHED' && !!watermarkMedia?.generatedMedia?.length
          if (watermarkLoaded) {
            commit('SET_WORKSPACE_WATERMARK_LOADING_STATUS', false)
            commit('UPDATE_CURRENT_WORKSPACE', { watermarkMedia })
            state.workspaceWatermarkSub.unsubscribe()
            state.workspaceWatermarkSub = null
          }
        })
    },
    setPreferredWorkspace({rootGetters}, workspaceId) {
      const userId = rootGetters['auth/userId']
      localStorage.setItem(`preferredWorkspace_${userId}`, workspaceId)
    },
    getPreferredWorkspace({rootGetters}) {
      const userId = rootGetters['auth/userId']
      return localStorage.getItem(`preferredWorkspace_${userId}`)
    }

  },
  getters: {
    workspaceId: state => state.workspace?.id || '',
    workspaceName: state => state.workspace?.name || '',
    workspaceShortId: state => state.workspace?.shortId || '',
    workspace: state => state.workspace,
    workspaceActivityLogsData: state => state.workspaceActivityLogs?.data,
    workspaceActivityLogsPagination: state => state.workspaceActivityLogs?.pagination,
    workspaceActivityLogs: state => state.workspaceActivityLogs,
    workspaceActivityLogsLoading: state => state.workspaceActivityLogsLoading,
    workspacesList: state => state.workspacesList,
    pendingStripeSubscriptionUpdate: state => state.workspace?.pendingStripeSubscriptionUpdate,
    workspaceMembers: state => state.workspaceMembers,
    workspaceMemberRole: state => state.workspace?.myMemberRole?.role,
    workspaceBillingInfo: state => state.workspace?.billingInfo,
    eduTierApplication: state => state.workspace?.eduTierApplication || {},
    billingDevicesQuantity: state => state.workspace?.billingInfo?.billingDevicesQuantity || 0,
    billingInterval: state => state.workspace?.billingInfo?.billingInterval,
    freeDevicesQuantity: state => state.workspace?.billingInfo?.freeDevicesQuantity || 0,
    workspacePendingInvites: state => state.workspacePendingInvites,
    workspaceNotificationSettings: state => state.workspace?.myMemberRole?.notificationSettings?.deviceStatusChanges?.enabled,
    workspaceHideWatermark: state => state.workspace?.settings?.hideWatermark,
    workspaceUseCustomWatermark: state => state.workspace?.settings?.useCustomWatermark,
    workspaceEmergencyFeedUrl: state => state.workspace?.settings?.emergencyFeedUrl,
    workspaceEmergencyFeedEnabled: state => state.workspace?.settings?.emergencyFeedEnabled,
    workspaceEmergencyFeedDeviceMatchingRule: state => state.workspace?.settings?.emergencyFeedDeviceMatchingRule,
    workspaceLogoLoadingStatus: state => state.workspaceLogoLoadingStatus,
    workspaceFaviconLoadingStatus: state => state.workspaceFaviconLoadingStatus,
    workspaceWatermarkLoadingStatus: state => state.workspaceWatermarkLoadingStatus,
    workspaceAccentColor: state => state.workspace?.settings?.whitelabelAccentColor,
    workspaceDemoStorageSizeLimit: state => state.workspace?.settings?.demoStorageSizeLimit ? formatFileSize(state.workspace?.settings?.demoStorageSizeLimit) : null,
    workspaceWhitelabelDomain: state => state.workspace?.whitelabel,
    workspaceLogo: state => state.workspace?.whitelabelLogoMedia?.generatedMedia?.[0]?.url,
    workspaceFavicon: state => state.workspace?.whitelabelFaviconMedia?.generatedMedia?.[0]?.url || state.workspace?.whitelabelFaviconMedia?.url,
    workspaceWatermark: state => state.workspace?.watermarkMedia?.generatedMedia?.[0]?.url || state.workspace?.watermarkMedia?.url,
    workspaceSSOIdentityProvider: state => state.workspaceSSOIdentityProvider,
    changingWorkspace: state => state.changingWorkspace,
    deviceInitializationToken: state => state.workspace?.deviceInitializationToken,
    isBusinessTier: state => state.workspace?.billingInfo?.tierGroup === 'business',
    isEducationTier: state => state.workspace?.billingInfo?.tierGroup === 'education',
    isFrozen: state => state.workspace?.frozen,
    frozenReason: state => state.workspace?.frozenReason,
    isManuallyFrozen: state => state.workspace?.frozen && state.workspace?.frozenReason === 'MANUALLY_FROZEN',
    isFrozenTooManyDevices: state => state.workspace?.frozen && state.workspace?.frozenReason === 'FROZEN_TOO_MANY_DEVICES',
    isEnterprize: state => state.workspace?.billingInfo?.enterprize,
    isInactive: state => state.workspace?.billingInfo?.status === 'inactive' || state.workspace?.billingInfo?.status === 'incomplete',
    isPaid: state => state.workspace?.billingInfo?.status === 'paid' && state.workspace?.billingInfo?.tier !== EDUCATION_STARTER_TIER,
    isUnpaid: state => state.workspace?.billingInfo?.status === 'unpaid',
    isPastDue: state => state.workspace?.billingInfo?.status === 'past_due',
    isPaused: state => state.workspace?.billingInfo?.paused,
    isTrialTier: state => isTierTrial(state.workspace?.billingInfo?.tier),
    isBusinessGrowthTier: state => state.workspace?.billingInfo?.tier === BUSINESS_GROWTH_TIER,
    isEducationStarterTier: state => state.workspace?.billingInfo?.tier === EDUCATION_STARTER_TIER,
    isEducationGrowthTier: state => state.workspace?.billingInfo?.tier === EDUCATION_GROWTH_TIER,
    isTrial: state => isTierTrial(state.workspace?.billingInfo?.tier) && state.workspace?.billingInfo?.status === 'trialing',
    trialIsOver: state => isTierTrial(state.workspace?.billingInfo?.tier) && state.workspace?.billingInfo?.status !== 'trialing',
    currentPeriodEnds: state => state.workspace?.billingInfo?.currentPeriodEnd,
    currentPeriodEndsFormatted: state => (format = 'MMMM D') => moment(  state.workspace?.billingInfo?.currentPeriodEnd || null).format(format),
    currentPeriodStarts: state => state.workspace?.billingInfo?.currentPeriodStart,
    trialDaysLeft: state => state.workspace?.billingInfo?.status === 'trialing' && moment(state.workspace?.billingInfo?.currentPeriodEnd).diff(moment(), 'days'),
    stripeCustomPriceId: state => state.workspace?.billingInfo?.stripeCustomPriceId,
    hasCustomPrice: state => !!state.workspace?.billingInfo?.stripeCustomPriceId,
    isOwner: state => state.workspace?.myRole === ROLES.OWNER,
    isAdmin: state => state.workspace?.myRole === ROLES.ADMIN,
    isManager: state => state.workspace?.myMemberRole?.role === ROLES.MANAGER,
    isAuthor: state => state.workspace?.myMemberRole?.role === ROLES.AUTHOR,
    ssoEnabled: state => !!state.workspace?.features?.ssoEnabled,
    slideEditModalEnabled: state => !!state.workspace?.features?.slideEditModalEnabled,
    forcedWatermarkToggleEnabled: state => !!state.workspace?.features?.forcedWatermarkToggleEnabled,
    nestedGroupsEnabled: state => !!state.workspace?.features?.nestedGroupsEnabled,
    externalApiEnabled: state => !!state.workspace?.features?.externalApiEnabled,
    playlistVersionsEnabled: state => !!state.workspace?.features?.playlistVersionsEnabled,
    whitelabelEnabled: state => !!state.workspace?.features?.whitelabelEnabled,
    menuItems: state => state.workspace?.appearance?.menuItems || [],
    customWatermarkEnabled: state => !!state.workspace?.features?.customWatermarkEnabled,
    smartGroupsEnabled: state => !!state.workspace?.features?.smartGroupsEnabled,
    authorRoleEnabled: state => !!state.workspace?.features?.authorRoleEnabled,
    multipleSocialAccountsEnabled: state => !!state.workspace?.features?.multipleSocialAccountsEnabled,
    enterpriseRequestFormSubmittedAt: state => state.workspace?.enterpriseRequestFormSubmittedAt,
    workspaceLoaded: state => state.workspaceLoaded,
  },
  mutations: {
    SET_CURRENT_WORKSPACE (state, { workspace }) {
      state.workspace = workspace
    },
    UPDATE_CURRENT_WORKSPACE (state, payload) {
      state.workspace = { ...state.workspace, ...payload }
      if (!state.workspacesList?.length) return
      state.workspacesList = state.workspacesList.map(workspace => workspace.id === state.workspace.id ? state.workspace : workspace)
    },
    SET_WORKSPACE_MEMBERS (state, members) {
      state.workspaceMembers = members
    },
    REMOVE_WORKSPACE_MEMBER_BY_ID (state, userId) {
      state.workspaceMembers = state.workspaceMembers.filter((member)=>member.user.id !== userId)
    },
    UPDATE_WORKSPACE_MEMBER_BY_ID (state, member) {
      state.workspaceMembers = state.workspaceMembers.map((m)=>m.userId === member.userId ? member : m)
    },
    SET_WORKSPACE_PENDING_INVITES (state, invites) {
      state.workspacePendingInvites = invites
    },
    ADD_WORKSPACE_PENDING_INVITE (state, invite) {
      state.workspacePendingInvites = [invite, ...state.workspacePendingInvites]
    },
    REMOVE_WORKSPACE_PENDING_INVITE (state, email) {
      state.workspacePendingInvites = state.workspacePendingInvites.filter(invite => invite.email !== email)
    },
    UPDATE_CURRENT_WORKSPACE_MEMBER_ROLE (state, payload) {
      state.workspace = { ...state.workspace, myMemberRole: { ...state.workspace.myMemberRole, ...payload } }
    },
    SET_SSO_IDENTITY_PROVIDER (state, provider) {
      state.workspaceSSOIdentityProvider = provider
    },
    UNSET_SSO_IDENTITY_PROVIDER (state) {
      state.workspaceSSOIdentityProvider = null
    },
    SET_AVAILABLE_WORKSPACES (state, workspacesList) {
      state.workspacesList = workspacesList
    },
    SET_WORKSPACE_LOGO_LOADING_STATUS (state, status) {
      state.workspaceLogoLoadingStatus = status
    },
    SET_WORKSPACE_WATERMARK_LOADING_STATUS (state, status) {
      state.workspaceWatermarkLoadingStatus = status
    },
    SET_WORKSPACE_FAVICON_LOADING_STATUS (state, status) {
      state.workspaceFaviconLoadingStatus = status
    },
    SET_WORKSPACE_ACTIVITY_LOGS (state, { data, pagination }) {
      state.workspaceActivityLogs = { data, pagination }
    },
    ADD_WORKSPACE_ACTIVITY_LOGS (state, { data, pagination }) {
      state.workspaceActivityLogs = { data: [...state.workspaceActivityLogs?.data, ...data], pagination }
    },
    CLEAR_WORKSPACE_DATA (state) {
      state.workspace = null
      state.workspaceLoaded = false
      state.workspaceActivityLogs = null
      state.workspaceSSOIdentityProvider = null
      state.workspaceMembers = []
      state.workspacePendingInvites = []
    }
  }
}
