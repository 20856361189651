


<template>
  <a-button
    v-if="onlyOneSocial"
    :disabled="buttonDisabled"
    @click="addSocialAccount(socialItems?.[0]?.type)"
  >
    <template #icon>
      <PlusOutlined />
    </template>
    {{ $t('components.addSocialAccountButton.addSocialAccountButtonText') }}
  </a-button>
  <a-dropdown
    v-else
    placement="bottom"
    :disabled="socialItems?.length === 0"
    :trigger="['click']"
  >
    <a-button>
      <template #icon>
        <PlusOutlined />
      </template>
      {{ $t('components.addSocialAccountButton.addSocialAccountButtonText') }}
    </a-button>
    <template #overlay>
      <a-menu>
        <a-menu-item
          v-for="social in socialItems"
          :key="social.type"
          @click="addSocialAccount(social.type)"
        >
          <template #icon>
            <component :is="social.icon" />
          </template>
          {{ social.name }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { computed, defineComponent } from 'vue'
import {
  DropboxOutlined,
  GoogleOutlined,
  InstagramOutlined,
  PlusOutlined,
  WindowsOutlined
} from '@ant-design/icons-vue'
import { SOCIALS } from '@/constants'
import { addAccount, toStandard } from '@/helpers/SocialAccount'
import TwitterOutlined from '@/components/icons/TwitterOutlined'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AddSocialAccountButton',
  components: {
    PlusOutlined,
    InstagramOutlined,
    TwitterOutlined,
    GoogleOutlined,
    WindowsOutlined,
    DropboxOutlined
  },
  props: {
    socials: {
      type: Array,
      validator: function (array) {
        return array.every(social => SOCIALS.map(({ type }) => type).includes(social))
      }
    }
  },
  setup (props) {
    const onlyOneSocial = computed(()=> props.socials?.length === 1)
    const store = useStore()
    const multipleSocialAccountsEnabled = computed(() => store.getters['workspace/multipleSocialAccountsEnabled'])
    const accounts = computed(() => store.getters['social/socialAccounts'].map(toStandard))
    const allowedSocials = computed(() => props.socials ? SOCIALS.filter(social => props.socials.includes(social.type)) : SOCIALS)
    const socialItems = computed(() => {
      const socials = allowedSocials.value
      if (multipleSocialAccountsEnabled.value) {
        return socials
      }
      return accounts.value.length === 0
        ? socials
        : socials.filter(social => !accounts.value.map(account => account.type?.toLowerCase()).includes(social.type))
    })

    const buttonDisabled = computed(() => {
      return !multipleSocialAccountsEnabled.value && !allowedSocials.value?.some(social => !accounts.value.map(account => account.type?.toLowerCase()).includes(social.type))
    })


    return {
      addSocialAccount: addAccount,
      socialItems,
      allowedSocials,
      onlyOneSocial,
      buttonDisabled
    }
  }
})
</script>

<style scoped>

</style>
