import ClockSlide from './ClockSlide'
import FeedSlide from './FeedSlide'
import ImageSlide from './ImageSlide'
import VideoSlide from './VideoSlide'
import DocumentSlide from './DocumentSlide'
import NewsSlide from './NewsSlide'
import QuotesSlide from './QuotesSlide'
import SocialActivitySlide from './SocialActivity'
import SocialTiktokSlide from './SocialTiktok'
import SocialTwitterSlide from './SocialTwitter'
import SocialInstagramSlide from './SocialInstagram'
import CalendarGoogleSlide from './CalendarGoogle'
import CalendarMicrosoftSlide from './CalendarMicrosoft'
import WeatherSlide from './WeatherSlide'
import StreamSlide from './StreamSlide'
import WebsiteSlide from './WebSlide'
import YelpSlide from './YelpSlide'
import TickerSlide from './TickerSlide'
import FunFactsSlide from './FunFactsSlide'
import HoroscopesSlide from './HoroscopesSlide'
import LiveScoreSlide from './LiveScoreSlide'
import PublicHolidaysSlide from './PublicHolidaysSlide'
import TEDTalksSlide from './TEDTalksSlide'
import TastySlide from './TastySlide'
import GoogleReviews from './GoogleReviewsSlide'
import TripAdvisorSlide from './TripAdvisorSlide'

export default {
  ClockSlide,
  FeedSlide,
  ImageSlide,
  VideoSlide,
  DocumentSlide,
  NewsSlide,
  QuotesSlide,
  SocialActivitySlide,
  SocialTwitterSlide,
  SocialInstagramSlide,
  SocialTiktokSlide,
  CalendarGoogleSlide,
  CalendarMicrosoftSlide,
  WeatherSlide,
  StreamSlide,
  WebsiteSlide,
  YelpSlide,
  GoogleReviews,
  TickerSlide,
  FunFactsSlide,
  HoroscopesSlide,
  LiveScoreSlide,
  PublicHolidaysSlide,
  TEDTalksSlide,
  TastySlide,
  TripAdvisorSlide
}
