<script setup>
import { onMounted, ref } from 'vue'
import { isURL } from 'validator'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { DEFAULT_PROTOCOL } from '@/constants'
import { normalizeUrl } from '@/utils'
import { useI18n } from 'vue-i18n'
import { FeedSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: FeedSlide,
  groupId: String,
  disabled: Boolean
})

const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const { t } = useI18n()
const slide = props.slideObj || new FeedSlide()
const { url } = slide.slideData
const inputRef = ref(null)
const feedUrl = ref(normalizeUrl(url))

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

onMounted(() => {
  inputRef.value && inputRef.value.focus()
})

const onSubmit = () => {
  if (!props.disabled) {
    emit('save')
  }
}

const updateSlide = () => {
  emit('update-slide', slide)
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const updateSlideData = () => {
  slide.updateSlideData({
    url: DEFAULT_PROTOCOL + feedUrl.value?.toLowerCase()
  })
}

const onChange = () => {
  const stripUrl = normalizeUrl(feedUrl.value)
  if (!isURL(DEFAULT_PROTOCOL + stripUrl)) {
    toggleDisabled(true)
    return true
  }
  feedUrl.value = stripUrl
  updateSlideData()
  updateSlide()
  toggleDisabled(false)
}
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.feed.slideStep1.subTitle')"
    :title="$t('components.slides.feed.slideStep1.subTitle')"
    no-border
  >
    <a-form @submit="onSubmit">
      <a-input
        ref="inputRef"
        v-model:value="feedUrl"
        :addon-before="DEFAULT_PROTOCOL"
        :placeholder="$t('components.slides.feed.slideStep1.urlPlaceholder')"
        @change="onChange"
      />
    </a-form>
  </SlideStep>
</template>

<style lang="less">

</style>
