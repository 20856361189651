<template>
  <a-modal
    :open="showCollaboration"
    width="960px"
    style="top: 20px"
    wrap-class-name="full-height-modal collaboration-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.collaborationModal.title')"
        :subtitle="$t('components.collaborationModal.subtitle')"
      />
    </template>
    <a-layout style="overflow: hidden">
      <AccessDrawer
        :show-drawer="showDrawer"
        :current-state="drawerState"
        @close-drawer="closeDrawer"
        @save-access="saveAccess"
      />
      <a-layout-content style="background-color: #fff; overflow-x: auto">
        <a-row
          justify="space-between"
          style="position: sticky; z-index: 1; top: -24px; padding-bottom: 16px; padding-top: 24px; margin-top: -24px;background-color: #fff"
        >
          <a-col flex="1">
            <NewInviteInputGroup
              ref="newInviteInputGroup"
              :loading="sendingInvite"
              :new-member-access="newMemberAccess"
              @edit-member-access="startNewMemberAccessEdit"
              @invite-member="inviteMember"
              @role-change="handleNewMemberRoleChange"
            />
          </a-col>
          <a-col flex="0 0 220px">
            <a-input-search
              v-model:value="userFilter"
              :placeholder="$t('components.collaborationModal.filterPlaceholder')"
              style="width: 220px"
            />
          </a-col>
        </a-row>
        <a-tabs v-model:activeKey="activeTab">
          <a-tab-pane
            key="members"
            :tab="$t('components.collaborationModal.membersTabTitle', {count: membersCountString})"
          >
            <MembersTab
              :available-groups-playlists-id-map="availableGroupsPlaylistsIdMap"
              :available-groups-id-map="availableGroupsIdMap"
              :user-filter="userFilter"
              :updating-member-access="updatingMemberAccess"
              :members="members"
              @access-change="startExistingMemberAccessEdit"
            />
          </a-tab-pane>
          <a-tab-pane
            key="invites"
            :tab="$t('components.collaborationModal.invitesTabTitle', {count: invitesCountString})"
            force-render
            :disabled="!invitesCount"
          >
            <InvitesTab
              :available-groups-playlists-id-map="availableGroupsPlaylistsIdMap"
              :available-groups-id-map="availableGroupsIdMap"
              :user-filter="userFilter"
              :invites="invites"
            />
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>
    </a-layout>
    <template #footer>
      <a-button
        type="primary"
        @click="closeModal"
      >
        {{ $t('components.collaborationModal.closeButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { error, formatDate, getUserAvatarPlaceholder, success } from '@/utils'
import { useI18n } from 'vue-i18n'
import ModalHeading from '@/components/ModalHeading.vue'
import AccessDrawer from '@/components/rootModals/collaborationModal/AccessDrawer.vue'
import MembersTab from '@/components/rootModals/collaborationModal/MembersTab.vue'
import InvitesTab from '@/components/rootModals/collaborationModal/InvitesTab.vue'
import NewInviteInputGroup from '@/components/rootModals/collaborationModal/NewInviteInputGroup.vue'
import { ROLES } from '@/constants'

const RESTRICTION_PARAMS_MAP = {
  [ROLES.MANAGER]: 'groupIds',
  [ROLES.AUTHOR]: 'playlistIds'
}

export default defineComponent({
  name: 'CollaborationModal',
  components: {
    NewInviteInputGroup,
    InvitesTab,
    MembersTab,
    AccessDrawer,
    ModalHeading
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const userFilter = ref('')
    const newMemberAccess = ref([])
    const newInviteInputGroup = ref(null)
    const sendingInvite = ref(false)
    const updatingMemberAccess = ref(false)
    const showDrawer = ref(false)
    const activeTab = ref('members')
    const smartGroupsEnabled = computed(()=> store.getters['workspace/smartGroupsEnabled'])
    const authorRoleEnabled = computed(()=> store.getters['workspace/authorRoleEnabled'])
    const standardGroupsSelectableTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ checkable: true }))
    const smartGroupsSelectableTree = computed(() => store.getters['groups/availableSmartGroupsTree']({ checkable: true }))
    const standardGroupsPlaylistsSelectableTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ selectablePlaylists: true }))
    const smartGroupsPlaylistsSelectableTree = computed(() => store.getters['groups/availableSmartGroupsTree']({ selectablePlaylists: true }))
    const availableStandardGroupsIdMap = computed(() => store.getters['groups/availableStandardGroupsIdMap'])
    const availableSmartGroupsIdMap = computed(() => store.getters['groups/availableSmartGroupsIdMap'])
    const availableStandardGroupsPlaylistsIdMap = computed(() => store.getters['groups/availableStandardGroupsPlaylistsIdMap'])
    const availableSmartGroupsPlaylistsIdMap = computed(() => store.getters['groups/availableSmartGroupsPlaylistsIdMap'])
    const showCollaboration = computed(() => store.getters.showCollaboration)
    const members = computed(() => store.getters['workspace/workspaceMembers'].map(member => {
      return {
        userId: member.user?.id,
        name: `${member.user?.firstName} ${member.user?.lastName}`,
        email: member.user?.email,
        avatar: member.user?.avatarMedia?.generatedMedia?.[0]?.url || getUserAvatarPlaceholder(member.user.id),
        role: member.role,
        restrictions: member.restrictions ? member.restrictions[RESTRICTION_PARAMS_MAP[member.role]] : []
      }
    }))
    const invites = computed(() => store.getters['workspace/workspacePendingInvites'].map(invite => {
      return {
        id: invite.id,
        email: invite.email,
        role: invite.role,
        sent: formatDate(invite.createdAt),
        restrictions: invite.restrictions ? invite.restrictions[RESTRICTION_PARAMS_MAP[invite.role]] : []
      }
    }))

    const groupsSelectableTree = computed(() => {
      if (smartGroupsEnabled.value && smartGroupsSelectableTree.value.length) {
        if (standardGroupsSelectableTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            checkable: false,
            selectable: false,
            children : standardGroupsSelectableTree.value
          },
            {
              title: 'Smart Groups',
              key: 'smart-groups',
              value: 'smart-groups',
              checkable: false,
              selectable: false,
              children : smartGroupsSelectableTree.value
            }]
        }
        else {
          return smartGroupsSelectableTree.value
        }
      }
      else {
        return standardGroupsSelectableTree.value
      }
    })

    const playlistsSelectableTree = computed(() => {
      if (smartGroupsEnabled.value && smartGroupsPlaylistsSelectableTree.value.length) {
        if (standardGroupsPlaylistsSelectableTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            checkable: false,
            selectable: false,
            children : standardGroupsPlaylistsSelectableTree.value
          },
          {
            title: 'Smart Groups',
            key: 'smart-groups',
            value: 'smart-groups',
            checkable: false,
            selectable: false,
            children : smartGroupsPlaylistsSelectableTree.value
          }]
        }
        else {
          return smartGroupsPlaylistsSelectableTree.value
        }
      }
      else {
        return standardGroupsPlaylistsSelectableTree.value
      }
    })

    const availableGroupsIdMap = computed(() => ({
      ...availableStandardGroupsIdMap.value || null,
      ...availableSmartGroupsIdMap.value || null
    }))

    const availableGroupsPlaylistsIdMap = computed(() => ({
      ...availableStandardGroupsPlaylistsIdMap.value || null,
      ...availableSmartGroupsPlaylistsIdMap.value || null
    }))

    const membersCount = computed(() => {
      return members.value?.length || null
    })

    const membersCountString = computed(() => {
      return membersCount.value ? ` (${membersCount.value})` : ''
    })

    const invitesCount = computed(() => {
      return invites.value?.length || null
    })
    const invitesCountString = computed(() => {
      return invitesCount.value ? ` (${invitesCount.value})` : ''
    })

    const drawerState = reactive({
      type: '',
      member: null,
      treeData: [],
      treeCheckedKeys: []
    })


    const inviteMember = ({email, role}) => {
      sendingInvite.value = true
      const input = {
        role: role,
        email: email,
        ...(RESTRICTION_PARAMS_MAP[role] ? {
          restrictions: {
            [RESTRICTION_PARAMS_MAP[role]]: newMemberAccess.value?.length ? newMemberAccess.value : null
          }
        } : {})
      }
      store.dispatch('workspace/sendInvite', input).then(() => {
        success()
        resetInviteForm()
      }).catch(e => {
        switch (e.message) {
          case 'USER_IS_ALREADY_MEMBER':
            error(t('components.collaborationModal.userAlreadyHasAccess'))
            break
          case 'USER_IS_ALREADY_INVITED':
            error(t('components.collaborationModal.userAlreadyHasPendingInvite'))
            break
          default:
            error(e.message)
        }
      }).then(() => {
        sendingInvite.value = false
      })
    }

    const filterGroupsLabels = (groups = []) => {
      return groups.filter((id)=>!['smart-groups', 'groups'].includes(id))
    }

    const handleNewMemberRoleChange = () => {
      newMemberAccess.value = []
    }

    const resetInviteForm = () => {
      newInviteInputGroup.value?.resetForm()
      newMemberAccess.value = []
    }

    const closeModal = () => {
      resetInviteForm()
      store.dispatch('closeCollaboration')
    }

    const closeDrawer = () => {
      showDrawer.value = false
    }

    const startExistingMemberAccessEdit = (member) => {
      drawerState.member = member
      drawerState.treeCheckedKeys = member.restrictions || []
      switch (member.role) {
        case ROLES.MANAGER:
          drawerState.type = 'groupIds'
          drawerState.treeData = groupsSelectableTree.value
          break
        case ROLES.AUTHOR:
          drawerState.type = 'playlistIds'
          drawerState.treeData = playlistsSelectableTree.value
          break
      }
      openDrawer()
    }

    const startNewMemberAccessEdit = (role) => {
      drawerState.member = null
      drawerState.treeCheckedKeys = newMemberAccess.value
      switch (role) {
        case ROLES.MANAGER:
          drawerState.type = 'groupIds'
          drawerState.treeData = groupsSelectableTree.value
          break
        case ROLES.AUTHOR:
          drawerState.type = 'playlistIds'
          drawerState.treeData = playlistsSelectableTree.value
          break
      }
      openDrawer()
    }

    const saveAccess = (checkedKeys) => {
      const access = filterGroupsLabels(checkedKeys)
      if (!drawerState.member) {
        newMemberAccess.value = access
      }
      else {
        updateMemberAccess(drawerState.member, access)
      }
      showDrawer.value = false
    }

    const updateMemberAccess = (member, access) => {
      updatingMemberAccess.value = true
      store.dispatch('workspace/updateWorkspaceMember', {
        userId: member.userId,
        role: member.role,
        restrictions: {
          [RESTRICTION_PARAMS_MAP[member.role]]: access.length ? access : null
        }
      }).then(() => {
        success()
      }).catch(e => {
        error(e.message)
      }).finally(() => {
        updatingMemberAccess.value = false
      })
    }

    const openDrawer = () => {
      showDrawer.value = true
    }

    watch(invitesCount, (count) => {
      if (!count) {
        activeTab.value = 'members'
      }
    })

    return {
      members,
      invites,
      showDrawer,
      newInviteInputGroup,
      availableGroupsIdMap,
      availableGroupsPlaylistsIdMap,
      showCollaboration,
      groupsSelectableTree,
      membersCountString,
      invitesCount,
      invitesCountString,
      newMemberAccess,
      authorRoleEnabled,
      sendingInvite,
      activeTab,
      drawerState,
      userFilter,
      updatingMemberAccess,
      inviteMember,
      closeModal,
      closeDrawer,
      openDrawer,
      saveAccess,
      startNewMemberAccessEdit,
      startExistingMemberAccessEdit,
      handleNewMemberRoleChange
    }
  },
  computed: {
    ROLES () {
      return ROLES
    }
  }
})
</script>

<style lang="less">
.collaboration-modal {
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
    .ant-layout {
      height: 100%;
    }
  }
  #role-dropdown-button {
    text-transform: capitalize;
    width: 140px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center
  }
}
.role-dropdown {
  .ant-dropdown-menu {
    max-width: 300px;
    .ant-dropdown-menu-item {
      small {
        opacity: 0.5;
      }
      &:hover {
        background-color: var(--ant-primary-1);
        small {
          opacity: 1;
        }
      }
    }
  }
}
</style>
