<template>
  <LoadingView
    v-if="loading"
    :show="!!loading"
  />
  <PixieComponent
    ref="pixieRef"
    @close="handleClose"
    @update="handleUpdate"
  />
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { CustomSlide } from '@/helpers/Slides'
import PixieComponent from '@/components/slideModal/PixieComponent.vue'
import { useStore } from 'vuex'
import LoadingView from '@/components/LoadingView.vue'
import { error, getComposerAssets, loadJson } from '@/utils'
import { ASPECT_RATIO_MAP } from '@/constants'
const getAssets = getComposerAssets()

class ImageForPixie {
  id
  preview
  source
  constructor (media) {
    this.id = media.id
    this.preview = media.generatedMedia?.find(m => m.tag === 'preview')?.url
    this.source = media.url
  }

}

export default defineComponent({
  name: 'CustomTemplateModal',
  components: {
    LoadingView,
    PixieComponent
  },
  props: {
    visible: Boolean,
    slideObj: CustomSlide,
    zoningLayoutType: String,
    zoneKey: String
  },
  emits: ['update', 'update:visible', 'close'],
  setup (props, { emit }) {
    const pixieRef = ref()
    const store = useStore()
    const slide = ref(props.slideObj || new CustomSlide())
    const widgetJson = ref(slide.value?.slideData?.json)
    const aspectRatio = ref(slide.value?.slideData?.aspectRatio)
    const widgetJsonLoaded = ref(!slide.value?.widgetId)
    const mediaList = computed(()=> store.getters['media/mediaList'] || [])
    const images = computed(() => mediaList.value?.filter(m=>m.mimetype?.startsWith('IMAGE'))?.map(m => new ImageForPixie(m)) || [])
    const mediaListLoaded = computed(()=> store.getters['media/mediaListLoaded'])
    const loading = computed(()=> props.visible && (!mediaListLoaded.value || !widgetJsonLoaded.value || !templatesLoaded.value))
    const templatesRaw = computed(()=> store.getters['slides/defaultCustomTemplates'])
    const slideExists = computed(()=> !!slide.value)
    const templatesLoaded = computed(()=> store.getters['slides/defaultCustomTemplatesLoaded'])
    const templates = computed(() => templatesRaw.value.map((t, index) => {
      return {
        name: t.name,
        tags: t.tags?.map(t => t.toLowerCase()),
        index,
        url: t?.jsonUrl,
        preview: t?.previewUrl
      }
    }))

    watch([() => props.visible, () => mediaListLoaded.value, () => widgetJsonLoaded.value, () => slideExists.value, () => templatesLoaded.value], async ([modalVisible, mediaLoaded, jsonLoaded, slideExists, templatesLoaded]) => {
      if (modalVisible && mediaLoaded && jsonLoaded && !!slideExists && templatesLoaded) {
        const ar = aspectRatio.value || ASPECT_RATIO_MAP[props.zoningLayoutType]?.[props.zoneKey]
        if (!aspectRatio.value) {
          slide.value?.updateAspectRatio(ar)
          aspectRatio.value = slide.value?.slideData?.aspectRatio
        }
        pixieRef.value.openModal({assets: getAssets(), images: images.value, json: widgetJson.value, aspectRatio: ar, templates: [...templates.value], getTemplateJson: getTemplateJsonFromUrl})
      }
    })

    watch(() => props.visible, (visible) => {
      if (visible) {
        slide.value = props.slideObj || new CustomSlide()
        if (!mediaListLoaded.value) {
          store.dispatch('media/getMediaList')
          store.dispatch('slides/fetchDefaultComposerSlides')
        }
      }
      else {
        slide.value = null
        aspectRatio.value = null
      }
    })

    const handleUpdate = ({ img, json }) => {
      updateSlide({ img, json })
      pixieRef.value.closeModal()
      emit('update:visible', false)
      emit('close')
    }

    const handleClose = () => {
      emit('update:visible', false)
      emit('close')
    }

    const getTemplateJson = async (widgetId) => {
      return await store.dispatch('slides/fetchComposerJson', widgetId)
    }

    const getTemplateJsonFromUrl = async (url) => {
      const baseUrl = 'https://dashboard.next.kitcast.tv'
      const replacements = {
        [`${baseUrl}/composer`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer`,
        [`${baseUrl}/pixie/assets/images/gradients`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer/images/gradients`,
        [`${baseUrl}/pixie/assets/images/patterns`]: `${process.env.VUE_APP_STATIC_ASSETS}/composer/images/patterns`,
      }

      const json = await loadJson(url)

      // Replace sources for objects
      json.state.objects.forEach((obj) => {
        if (obj.type === "image") {
          for (const [key, value] of Object.entries(replacements)) {
            if (obj.src.startsWith(key)) {
              obj.src = obj.src.replace(key, value)
              break
            }
          }
        }
      })

      // Replace background source
      if (json.state?.background?.source) {
        for (const [key, value] of Object.entries(replacements)) {
          if (json.state.background.source.startsWith(key)) {
            json.state.background.source = json.state.background.source.replace(key, value)
            break
          }
        }
      }

      return json
    }

    const updateSlide = ({img, json}) => {
      slide.value.setSlideData({
        imageBase64: img,
        json,
        aspectRatio: aspectRatio.value
      })
      emit('update', slide.value?.getDto())
    }

    const loadWidgetJson = async () => {
      const widgetId = slide.value?.widgetId
      if (!widgetId) {
        widgetJsonLoaded.value = true
        widgetJson.value = {}
        return
      }
      widgetJsonLoaded.value = false
      widgetJson.value = await getTemplateJson(widgetId)
      widgetJsonLoaded.value = true
    }

    watch(() => props.slideObj, async (value) => {
      if (value && value.widgetType !== 'IMAGE_COMPOSER') return
      slide.value = value ? props.slideObj : new CustomSlide()
      aspectRatio.value = slide.value?.slideData?.aspectRatio
      try {
        await loadWidgetJson()
      }
      catch (e) {
        error(e.message)
        emit('update:visible', false)
      }
    })

    return {
      pixieRef,
      loading,
      handleUpdate,
      handleClose
    }
  }
})
</script>

<style lang="less">

</style>
