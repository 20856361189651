<template>
  <a-modal
    :open="visible"
    :title="isNewGroup ? $t('components.groupsSidebar.createGroup') : $t('components.groupsSidebar.editGroup')"
    :ok-button-props="{ disabled: groupName.length < MIN_GROUP_NAME_LENGTH, loading: someGroupsLoading}"
    :ok-text="$t('components.groupsSidebar.createModalOkText')"
    :cancel-text="$t('components.groupsSidebar.createModalCancelText')"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form
      layout="vertical"
      @submit.prevent="handleSubmit"
    >
      <a-form-item
        v-if="nestedGroupsEnabled && !isSmartGroup"
        :label="$t('components.groupsSidebar.parentGroupNameLabel')"
        name="parentGroup"
      >
        <a-tree-select
          v-model:value="parentGroupId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :placeholder="$t('components.groupsSidebar.parentGroupNamePlaceholder')"
          allow-clear
          tree-default-expand-all
          :tree-data="groupsTreeAvailableForSubGroups"
        />
      </a-form-item>
      <a-form-item
        :label="$t('components.groupsSidebar.groupNameLabel')"
        name="groupName"
      >
        <a-input
          v-model:value="groupName"
          :placeholder="$t('components.groupsSidebar.groupNamePlaceholder')"
        />
      </a-form-item>
      <a-form-item
        v-if="!isSmartGroup"
        :label="$t('components.groupsSidebar.languageLabel')"
      >
        <a-select
          v-model:value="groupLanguage"
          :options="languagesListOptions"
        />
      </a-form-item>
      <input
        type="submit"
        style="visibility: hidden;"
      >
    </a-form>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { LANGUAGES_LIST, MAX_GROUPS_DEPTH } from '@/constants'
import { useI18n } from 'vue-i18n'

const MIN_GROUP_NAME_LENGTH = 3

export default defineComponent({
  name: 'CreateEditGroupModal',
  props: {
    currentGroupName: String,
    visible: Boolean,
    nestedGroupsEnabled: Boolean,
    currentParentGroupId: String,
    currentGroupLanguage: String,
    excludeGroupId: {
      type: String,
      default: null
    },
    isSmartGroup: Boolean
  },
  emits: ['update', 'create', 'cancel'],
  setup (props, {emit}) {

    const store = useStore()
    const { t } = useI18n()
    const someGroupsLoading = computed(() => store.getters['groups/someGroupsLoading'])
    const groupsTreeAvailableForSubGroups = computed(() => store.getters['groups/availableStandardGroupsTree']({
      maxGroupsDepth: MAX_GROUPS_DEPTH,
      groupIdToExclude: props.excludeGroupId,
    }))
    const groupName = ref(props.currentGroupName)
    const parentGroupId = ref(props.currentParentGroupId)
    const isNewGroup = computed(()=>!props.currentGroupName)
    const groupLanguage = ref(props.currentGroupLanguage)
    const languagesListOptions = computed(()=> [{value: null, label: parentGroupId.value ? t('languages.asParent') : t('languages.default')}, ...LANGUAGES_LIST.map((key) => ({ value: key, label: t(`languages.${key}`)}))])

    const handleSubmit = () => {
      if (groupName.value?.length < MIN_GROUP_NAME_LENGTH) return
      const payload = {groupName: groupName.value, parentGroupId: parentGroupId.value, language: groupLanguage.value}
      emit(isNewGroup.value ? 'create' : 'update', payload)
    }

    const handleCancel = () => {
      parentGroupId.value = null
      emit('cancel')
    }

    const resetForm = () => {
      groupLanguage.value = null
      groupName.value = ''
    }

    watchEffect(()=>{
      groupName.value = props.currentGroupName
    })

    watchEffect(()=>{
      groupLanguage.value = props.currentGroupLanguage
    })

    watchEffect(()=>{
      parentGroupId.value = props.currentParentGroupId
    })

    watchEffect(()=>{
      if (props.visible && isNewGroup.value) {
        resetForm()
      }
    })

    return {
      gr: props.currentGroupName,
      groupName,
      isNewGroup,
      parentGroupId,
      someGroupsLoading,
      groupsTreeAvailableForSubGroups,
      languagesListOptions,
      groupLanguage,
      MIN_GROUP_NAME_LENGTH,
      handleSubmit,
      handleCancel
    }
  }
})
</script>

<style lang="less">

</style>
