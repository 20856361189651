<script setup>
import { computed, nextTick, reactive, ref, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { error } from '@/utils'

defineProps({
  showForm: Boolean
})

const STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error'
}

const { t } = useI18n()
const store = useStore()
const showOTPNeededModal = ref(false)
const loading = ref(false)
const optForPasswordSetup = ref('')
const otpForPasswordSent = ref(false)
const passwordSetupFormRef = ref(null)
const status = ref('pending')
const otpFormRef = ref(null)
const otpInputRef = ref(null)
const passwordFormState = reactive({
  password: '',
  confirmPassword: ''
})

const modalInfo = computed(() => {
  let info
  switch (status.value) {
    case STATUS.PENDING:
      info = t('components.securitySettings.setupPassModalInfo')
      break
    case STATUS.SUCCESS:
      info = t('components.securitySettings.setupPassModalInfoSuccess')
      break
    case STATUS.ERROR:
      info = t('components.securitySettings.setupPassModalInfoError')
      break
  }

  return info
})

const modalTitle = computed(() => {
  let title
  switch (status.value) {
    case STATUS.PENDING:
      title = t('components.securitySettings.setupPassModalTitle')
      break
    case STATUS.SUCCESS:
      title = t('components.securitySettings.setupPassModalTitleSuccess')
      break
    case STATUS.ERROR:
      title = t('components.securitySettings.setupPassModalTitleError')
      break
  }

  return title
})

const okButtonText = computed(() => {
  let text
  switch (status.value) {
    case STATUS.PENDING:
      text = t('components.securitySettings.setupPassModalOkButtonText')
      break
    case STATUS.SUCCESS:
      text = t('components.securitySettings.setupPassModalOkButtonTextSuccess')
      break
    case STATUS.ERROR:
      text = t('components.securitySettings.setupPassModalOkButtonTextError')
      break
  }

  return text
})

const validateConfirmPass = async (_rule, value) => {
  if (value === '') {
    // eslint-disable-next-line
    return Promise.reject(t('components.securitySettings.confirmPasswordRequiredError'))
  } else if (value !== passwordFormState.password) {
    // eslint-disable-next-line
    return Promise.reject(t('components.securitySettings.confirmPasswordMismatchError'))
  } else {
    return Promise.resolve()
  }
}

const modalOkFunction = () => {
  switch (status.value) {
    case STATUS.PENDING:
      setupPassword()
      break
    case STATUS.SUCCESS:
      handleOTPNeededModalCancel()
      resetPasswordSetupForm()
      break
    case STATUS.ERROR:
      optForPasswordSetup.value = ''
      otpForPasswordSent.value = false
      onSetupPassword()
      break
  }
}

const resetPasswordSetupForm = () => {
  passwordSetupFormRef.value?.resetFields()
  optForPasswordSetup.value = ''
}

const handleOTPNeededModalCancel = () => {
  showOTPNeededModal.value = false
  status.value = STATUS.PENDING
}

const onSetupPassword = async () => {
  showOTPNeededModal.value = true
  if (otpForPasswordSent.value) {
    status.value = STATUS.PENDING
    await nextTick()
    otpInputRef.value?.focus()
  } else {
    loading.value = true
    store.dispatch('auth/requestPasswordSetupConfirmationCode').then(async () => {
      otpForPasswordSent.value = true
      status.value = STATUS.PENDING
      await nextTick()
      otpInputRef.value?.focus()
    }).catch(() => {
      error(t('components.securitySettings.otpCodeForPasswordRateLimitError'))
    }).then(() => {
      loading.value = false
    })
  }
}



const setupPassword = async () => {
  loading.value = true
  const { confirmPassword, ...user } = toRaw(passwordFormState)
  user.setPasswordConfirmationCode = optForPasswordSetup.value
  store.dispatch('auth/updateProfile', user).then(() => {
    status.value = STATUS.SUCCESS
  }).catch(() => {
    status.value = STATUS.ERROR
  }).then(() => {
    loading.value = false
  })
}

const okButtonDisabled = computed(() => {
  let disabled = false
  switch (status.value) {
    case STATUS.PENDING:
      disabled = optForPasswordSetup.value?.length !== 6
      break
    case STATUS.SUCCESS:
    case STATUS.ERROR:
      disabled = false
      break
  }
  return disabled
})

const otpPasswordSetupRules = computed(()=>{
  return {
    otpCode: [{
      required: true,
      trigger: 'blur',
      message: t('components.securitySettings.otpCodeInvalidError')
    }]
  }
})

const passwordSetupRules = computed(()=>{
  return {
    password: [{
      required: true,
      trigger: 'blur',
      message: t('components.securitySettings.passwordInvalidError')
    },
      {
        trigger: 'blur',
        min: 8,
        message: t('components.securitySettings.passwordTooShortError')
      },
      {
        trigger: 'blur',
        max: 20,
        message: t('components.securitySettings.passwordTooLongError')
      }],
    confirmPassword: [{
      validator: validateConfirmPass,
      trigger: 'change'
    }]
  }
})

</script>

<template>
  <a-modal
    :open="showOTPNeededModal"
    width="300px"
    :title="modalTitle"
    :closable="false"
    :mask-closable="false"
    :cancel-button-props="{disabled: loading || status === STATUS.SUCCESS}"
    :ok-button-props="{loading, disabled: okButtonDisabled }"
    :ok-text="okButtonText"
    @ok="modalOkFunction"
    @cancel="handleOTPNeededModalCancel"
  >
    <a-typography-paragraph>
      {{ modalInfo }}
    </a-typography-paragraph>
    <a-form
      v-if="status === STATUS.PENDING"
      ref="otpFormRef"
      layout="vertical"
      :rules="otpPasswordSetupRules"
      style="margin-top: 16px;"
      :disabled="loading"
      @finish="setupPassword"
    >
      <a-form-item
        name="otpCodeForPasswordSetup"
      >
        <a-input
          ref="otpInputRef"
          v-model:value="optForPasswordSetup"
          :placeholder="$t('components.securitySettings.otpCodeForPasswordPlaceholder')"
          @press-enter="setupPassword"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-form
    v-if="showForm"
    ref="passwordSetupFormRef"
    layout="vertical"
    style="margin-bottom: 32px;"
    :model="passwordFormState"
    :rules="passwordSetupRules"
    @finish="onSetupPassword"
  >
    <a-row :gutter="0">
      <a-col :span="12">
        <a-form-item
          :label="$t('components.securitySettings.newPasswordLabel')"
          name="password"
        >
          <a-input-password
            v-model:value="passwordFormState.password"
            :placeholder="$t('components.securitySettings.newPasswordPlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="0">
      <a-col :span="12">
        <a-form-item
          :label="$t('components.securitySettings.confirmPasswordLabel')"
          name="confirmPassword"
        >
          <a-input-password
            v-model:value="passwordFormState.confirmPassword"
            :placeholder="$t('components.securitySettings.confirmPasswordPlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        :loading="loading"
      >
        {{ $t('components.securitySettings.setupButtonText') }}
      </a-button>
    </a-form-item>
  </a-form>
</template>

<style scoped lang="less">

</style>
