import { gql } from 'graphql-tag'
import {
  API_KEYS_BASE,
  BASIC_MEDIA_BASE,
  DEVICE_BASE,
  GROUP_SCHEDULE,
  PLAYLIST_BASE, PROMO_CODE_INFO,
  SMART_GROUP_BASE,
  SMART_GROUP_EXTENDED,
  SMART_GROUP_PLAYLISTS_BASE,
  SOCIAL_ACCOUNT_BASE,
  SOCIAL_ACCOUNT_DROPBOX_BASE,
  SOCIAL_ACCOUNT_GOOGLE_BASE,
  SOCIAL_ACCOUNT_INSTAGRAM_BASE,
  SOCIAL_ACCOUNT_MICROSOFT_BASE,
  SOCIAL_ACCOUNT_TWITTER_BASE,
  STANDARD_GROUP_BASE,
  STANDARD_GROUP_EXTENDED,
  STANDARD_GROUP_PLAYLISTS_BASE,
  SUBSCRIPTION,
  UPDATED_PLAYLIST,
  USER_BASE, USER_PROFILE_BASE,
  WEBHOOK_BASE,
  WORKSPACE_CUSTOMER,
  WORKSPACE_DIRECTORY_MEDIA_EXTENDED, WORKSPACE_EDITABLE, WORKSPACE_MEDIA_BASE, WORKSPACE_MEMBER,
  WORKSPACE_ROLE_NOTIFICATION_SETTINGS,
  WORKSPACE_ROLE_RESTRICTIONS,
  WORKSPACE_SETTINGS,
  WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED,
  WORKSPACE_URL_FILE_MEDIA_EXTENDED
} from '@/graphql/fragments'

// AUTHORIZATION

export const LOGIN_USER = gql`
  ${USER_BASE}
  mutation loginUser ($email: String! $password: String!, $whitelabel: String ){
    loginUser(email: $email password: $password whitelabel: $whitelabel ){
      sessionId
      accessToken
      preferableWorkspaceId
      user {
        ...userBase
      }
    }
  }
`

export const REQUEST_PASSWORD_SET_OTP_CODE= gql`
  mutation requestPasswordSetupConfirmationCode {
    requestPasswordSetupConfirmationCode
  }
`
export const PREPARE_2FA_SETUP = gql`
  mutation prepare2faSetup($password: String!) {
    prepare2faSetup(password: $password) {
      otpUrl
    }
  }
`

export const FINALIZE_2FA_SETUP = gql`
  mutation finalize2faSetup($twoFactorCode: String!) {
    finalize2faSetup(twoFactorCode: $twoFactorCode)
  }
`

export const DISABLE_2FA = gql`
  mutation disable2fa($password: String! $twoFactorCode: String!) {
    disable2fa(password: $password twoFactorCode: $twoFactorCode)
  }
`

export const PASS_TWO_FACTOR_CHECK = gql`
  ${USER_BASE}
  mutation passTwoFactorCheck($preAuthToken: String! $twoFactorCode: String!) {
    passTwoFactorCheck(preAuthToken: $preAuthToken twoFactorCode: $twoFactorCode) {
      sessionId
      accessToken
      user {
        ...userBase
      }
    }
  }
`

export const LOGIN_USER_GOOGLE_SSO = gql`
  ${USER_BASE}
  mutation loginUserSsoGoogle($code: String! $whitelabel: String) {
    loginUserSsoGoogle(code: $code whitelabel: $whitelabel) {
      sessionId
      isSignUp
      accessToken
      user { 
        ...userBase
      }
    }
  }
`

export const LOGIN_USER_MICROSOFT_SSO = gql`
  ${USER_BASE}
  mutation loginUserSsoMicrosoft($code: String! $whitelabel: String $scopes: [String!]) {
    loginUserSsoMicrosoft(code: $code whitelabel: $whitelabel scopes: $scopes) {
      sessionId
      isSignUp
      accessToken
      user {
        ...userBase
      }
    }
  }
`

export const LOGIN_USER_OIDC_SSO = gql`
  ${USER_BASE}
  mutation loginUserSsoOidc($code: String!, $state: String!) {
    loginUserSsoOidc(code: $code, state: $state) {
      sessionId
      isSignUp
      accessToken
      user {
        ...userBase
      }
    }
  }
`

export const REGISTER_USER = gql`
  ${USER_BASE}
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      accessToken
      user { 
        ...userBase
      }
    }
  }
`

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation requestMyEmailVerification {
    requestMyEmailVerification
  }
`

export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser
  }
`

export const ACCEPT_INVITE_BY_TOKEN = gql`
  mutation acceptInviteByToken ($token: String!) {
    acceptInviteByToken (token: $token)
  }
`

export const ACCEPT_INVITE_BY_ID = gql`
  mutation acceptInviteById ($id: String!) {
    acceptInviteById (id: $id)
  }
`

export const REJECT_INVITE_BY_TOKEN = gql`
  mutation rejectInviteByToken ($token: String!) {
    rejectInviteByToken (token: $token)
  }
`

export const REJECT_INVITE_BY_ID = gql`
  mutation rejectInviteById ($id: String!) {
    rejectInviteById (id: $id)
  }
`

export const RESEND_INVITE = gql`
  mutation resendInvite ($inviteId: String!) {
    resendInvite (inviteId: $inviteId) {
      id
    }
  }
`


export const REQUEST_USER_EMAIL_CHANGE = gql`
  mutation requestUserEmailChange($input: RequestUserEmailChangeInput!) {
    requestUserEmailChange(input: $input)
  }
`

export const CHANGE_USER_EMAIL = gql`
  mutation changeUserEmail($token: String!) {
    changeUserEmail(token: $token)
  }
`

export const REVERT_USER_EMAIL = gql`
  mutation revertUserEmail($token: String!) {
    revertUserEmail(token: $token)
  }
`

export const REQUEST_USER_PASSWORD_RESET = gql`
  mutation requestUserPasswordReset($input: RequestUserPasswordResetInput!) {
    requestUserPasswordReset(input: $input)
  }
`

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($input: ResetUserPasswordInput! $token: String!) {
    resetUserPassword(input: $input token: $token)
  }
`

export const SET_WORKSPACE_ID_FOR_SESSION = gql`
  mutation setWorkspaceForCurrentSession ($workspaceId: String!) {
    setWorkspaceForCurrentSession(workspaceId: $workspaceId)
  }
`
export const CREATE_CHECKOUT_SESSION = gql`
  mutation generateCheckoutSession {
    generateCheckoutSession {
      expires_at
      url
    }
  }
`

export const DELETE_SESSION_BY_ID = gql`
  mutation deleteSessionById ($sessionId: String!){
    deleteSessionById(sessionId: $sessionId)
  }
`

export const VERIFY_EMAIL = gql`
  mutation verifyEmail ($token: String!){
    verifyEmail(token: $token)
  }
`

// PROFILE

export const UPDATE_PROFILE = gql`
  ${USER_PROFILE_BASE}
  mutation updateMyProfile ($input: UpdateUserProfileInput!){
    updateMyProfile(input: $input) {
      ...userProfileBase
    }
  }
`

export const PREPARE_AVATAR_MEDIA = gql`
  ${BASIC_MEDIA_BASE}
  mutation prepareMyAvatarMedia ($input: MediaMimeTypes!){
    prepareMyAvatarMedia(input: $input) {
      ...basicMediaBase
    }
  }
`

export const FINALIZE_AVATAR_MEDIA = gql`
  ${BASIC_MEDIA_BASE}
  mutation finalizeMyAvatarMedia {
    finalizeMyAvatarMedia {
      ...basicMediaBase
    }
  }
`

export const DELETE_AVATAR_MEDIA = gql`
  mutation deleteMyAvatarMedia {
    deleteMyAvatarMedia 
  }
`

export const UPLOAD_AVATAR_MEDIA = gql`
  ${BASIC_MEDIA_BASE}
  mutation  ($file: Upload!){
    uploadMyAvatarMediaImage (file: $file) {
      ...basicMediaBase
    }
  }
`

export const SUBMIT_ENTERPRISE_REQUEST_FORM = gql`
  mutation submitEnterpriseRequestForm ($input: EnterpriseRequestFormInput!) {
    submitEnterpriseRequestForm (input: $input)
  } 
`

// WORKSPACES

export const UPDATE_WORKSPACE_BY_ID = gql`
  ${WORKSPACE_EDITABLE}
  mutation updateWorkspaceById ($id: String! $input: UpdateWorkspaceInput!) {
    updateWorkspaceById(id: $id input: $input) {
      ...workspaceEditable
    }
  }
`

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace ($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      id
    }
  }
`


export const UPDATE_WORKSPACE_MEMBER_BY_ID = gql`
  ${WORKSPACE_MEMBER}
  mutation updateCurrentWorkspaceMemberRole ($input : SetUserWorkspaceRoleInput!) {
    updateCurrentWorkspaceMemberRole(input: $input) {
      ...workspaceMember
    }
  }
`

export const REMOVE_WORKSPACE_MEMBER_BY_ID = gql`
  mutation deleteCurrentWorkspaceMemberRole ($userId: String!) {
    deleteCurrentWorkspaceMemberRole(userId: $userId)
  }
`

export const UPDATE_WORKSPACE_NOTIFICATION_SETTINGS = gql`
  ${WORKSPACE_ROLE_NOTIFICATION_SETTINGS}
  mutation updateMyCurrentWorkspaceMemberRoleSettings ($input: UpdateMyCurrentWorkspaceMemberRoleSettingsInput!) {
    updateMyCurrentWorkspaceMemberRoleSettings(input: $input) {
      notificationSettings {
        ...notificationSettings
      }
    }
  }
`

export const UPLOAD_WORKSPACE_LOGO = gql`
  ${BASIC_MEDIA_BASE}
  mutation uploadWorkspaceWhitelabelLogo ($workspaceId: String! $file: Upload!) {
    uploadWorkspaceWhitelabelLogo(workspaceId: $workspaceId file: $file) {
      ...basicMediaBase
    }
  }
`

export const DELETE_WORKSPACE_LOGO = gql`
  mutation deleteWorkspaceWhitelabelLogo ($workspaceId: String!) {
    deleteWorkspaceWhitelabelLogo(workspaceId: $workspaceId)
  }
`

export const UPLOAD_WORKSPACE_FAVICON = gql`
  ${BASIC_MEDIA_BASE}
  mutation uploadWorkspaceWhitelabelFavicon ($workspaceId: String! $file: Upload!) {
    uploadWorkspaceWhitelabelFavicon(workspaceId: $workspaceId file: $file) {
      ...basicMediaBase
    }
  }
`

export const DELETE_WORKSPACE_FAVICON = gql`
  mutation deleteWorkspaceWhitelabelFavicon ($workspaceId: String!) {
    deleteWorkspaceWhitelabelFavicon(workspaceId: $workspaceId)
  }
`
export const SET_WORKSPACE_SSO_IDENTITY_PROVIDER = gql`
  mutation setSsoIdentityProviderOidc ($input: SetSsoIdentityProviderOidcInput!) {
    setSsoIdentityProviderOidc(input: $input) {
      id
      type
      clientId
      clientSecret
      issuer
      createdAt
      updatedAt
      workspaceId
      authorIdentityId
    }
  }
`
export const UNSET_WORKSPACE_SSO_IDENTITY_PROVIDER = gql`
  mutation unsetSsoIdentityProvider {
    unsetSsoIdentityProvider
  }
`



export const SEND_INVITE = gql`
  ${WORKSPACE_ROLE_RESTRICTIONS}
  mutation sendInvite($input: InviteUserInput!) {
    sendInvite(input: $input) {
      id
      email
      status
      role
      restrictions {
        ...restrictions
      }
      createdAt
    }
  }
`

export const CANCEL_INVITE = gql`
  mutation cancelInvite($email: String!) {
    cancelInvite(email: $email)
  }
`

export const UPLOAD_WORKSPACE_WATERMARK = gql`
  ${BASIC_MEDIA_BASE}
  mutation ($workspaceId: String! $file: Upload!){
    uploadWorkspaceWatermark (workspaceId: $workspaceId file: $file) {
      ...basicMediaBase
    }
  }
`

export const DELETE_WORKSPACE_WATERMARK = gql`
  mutation deleteWorkspaceWatermark ($workspaceId: String!) {
    deleteWorkspaceWatermark(workspaceId: $workspaceId)
  }
`

// GROUPS

export const CREATE_STANDARD_GROUP = gql`
  ${STANDARD_GROUP_BASE}
  ${STANDARD_GROUP_PLAYLISTS_BASE}
  mutation createStandardGroup($input: CreateStandardGroupInput!) {
    createStandardGroup(input: $input) {
      ...standardGroupBase
      ...standardGroupPlaylistsBase
    }
  }
`

export const CREATE_SMART_GROUP = gql`
  ${SMART_GROUP_BASE}
  ${SMART_GROUP_PLAYLISTS_BASE}
  mutation createSmartGroup($input: CreateSmartGroupInput!) {
    createSmartGroup(input: $input) {
      ...smartGroupBase
      ...smartGroupPlaylistsBase
    }
  }
`

export const UPDATE_STANDARD_GROUP_BY_ID = gql`
  ${STANDARD_GROUP_BASE}
  ${STANDARD_GROUP_PLAYLISTS_BASE}
  mutation updateStandardGroupById($id: String! $input:UpdateStandardGroupInput!) {
    updateStandardGroupById(id: $id input: $input) {
      ...standardGroupBase
      ...standardGroupPlaylistsBase
    }
  }
`

export const UPDATE_STANDARD_GROUP_BY_ID_EXTENDED = gql`
  ${STANDARD_GROUP_EXTENDED}
  ${STANDARD_GROUP_PLAYLISTS_BASE}
  mutation updateStandardGroupById($id: String! $input:UpdateStandardGroupInput!) {
    updateStandardGroupById(id: $id input: $input) {
      ...standardGroupExtended
      ...standardGroupPlaylistsBase
    }
  }
`

export const UPDATE_SMART_GROUP_BY_ID = gql`
  ${SMART_GROUP_BASE}
  ${SMART_GROUP_PLAYLISTS_BASE}
  mutation updateSmartGroupById($id: String! $input:UpdateSmartGroupInput!) {
    updateSmartGroupById(id: $id input: $input) {
      ...smartGroupBase
      ...smartGroupPlaylistsBase
    }
  }
`

export const UPDATE_SMART_GROUP_BY_ID_EXTENDED = gql`
  ${SMART_GROUP_EXTENDED}
  ${SMART_GROUP_PLAYLISTS_BASE}
  mutation updateSmartGroupById($id: String! $input:UpdateSmartGroupInput!) {
    updateSmartGroupById(id: $id input: $input) {
      ...smartGroupExtended
      ...smartGroupPlaylistsBase
    }
  }
`

export const DELETE_STANDARD_GROUP_BY_ID = gql`
  mutation deleteStandardGroupById($id: String!) {
    deleteStandardGroupById(id: $id)
  }
`

export const DELETE_SMART_GROUP_BY_ID = gql`
  mutation deleteSmartGroupById($id: String!) {
    deleteSmartGroupById(id: $id)
  }
`

// MEDIA

export const CREATE_WORKSPACE_UPLOADABLE_FILE_MEDIA = gql`
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED}
  mutation createWorkspaceUploadableFileMedia($input: CreateWorkspaceUploadableFileMediaInput!) {
    createWorkspaceUploadableFileMedia(input: $input) {
      ...workspaceUploadableFileMediaExtended
    }
  }
`

export const CREATE_WORKSPACE_DIRECTORY_MEDIA = gql`
  ${WORKSPACE_DIRECTORY_MEDIA_EXTENDED}
  mutation createWorkspaceDirectoryMedia($input: CreateWorkspaceDirectoryMediaInput!) {
    createWorkspaceDirectoryMedia(input: $input) {
      ...workspaceDirectoryMediaExtended
    }
  }
`

export const CREATE_WORKSPACE_URL_FILE_MEDIA = gql`
  ${WORKSPACE_URL_FILE_MEDIA_EXTENDED}
  mutation createWorkspaceUrlFileMedia($input: CreateWorkspaceUrlFileMediaInput!) {
    createWorkspaceUrlFileMedia(input: $input) {
      ...workspaceUrlFileMediaExtended
    }
  }
`

export const UPDATE_WORKSPACE_DIRECTORY_MEDIA_BY_ID = gql`
  ${WORKSPACE_DIRECTORY_MEDIA_EXTENDED}
  mutation updateWorkspaceDirectoryMediaById($id: String! $input: UpdateWorkspaceDirectoryMediaInput!) {
    updateWorkspaceDirectoryMediaById(id: $id input: $input) {
      ...workspaceDirectoryMediaExtended
    }
  }
`
export const UPDATE_WORKSPACE_MEDIA_DELETE_OPTIONS = gql`
  mutation setWorkspaceMediaAutoDeleteOptionsByIds($ids: [String!]! $autoDeleteOptions: WorkspaceMediaAutoDeleteOptionsInput!) {
    setWorkspaceMediaAutoDeleteOptionsByIds(ids: $ids autoDeleteOptions: $autoDeleteOptions)
  }
`

export const UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID = gql`
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED}
  mutation updateWorkspaceUploadableFileMediaById($id: String! $input: UpdateWorkspaceUploadableFileMediaInput!) {
    updateWorkspaceUploadableFileMediaById(id: $id input: $input) {
      ...workspaceUploadableFileMediaExtended
    }
  }
`

export const UPDATE_WORKSPACE_URL_FILE_MEDIA_BY_ID = gql`
  ${WORKSPACE_DIRECTORY_MEDIA_EXTENDED}
  mutation updateWorkspaceUrlFileMediaById($id: String! $input: UpdateWorkspaceDirectoryMediaInput!) {
    updateWorkspaceUrlFileMediaById(id: $id input: $input) {
      ...workspaceDirectoryMediaExtended
    }
  }
`

export const DELETE_WORKSPACE_MEDIA_BY_ID = gql`
  mutation deleteWorkspaceMediaById($id: String! $force: Boolean) {
    deleteWorkspaceMediaById(id: $id force: $force)
  }
`

export const DELETE_WORKSPACE_MEDIA_BY_IDS = gql`
  mutation deleteWorkspaceMediaByIds($ids: [String!]! $force: Boolean) {
    deleteWorkspaceMediaByIds(ids: $ids force: $force)
  }
`

export const MOVE_WORKSPACE_MEDIA_BY_IDS = gql`
  mutation moveWorkspaceMediaByIds($ids: [String!]! $force: Boolean $input: MoveMultipleWorkspaceMediaInput!) {
    moveWorkspaceMediaByIds(ids: $ids force: $force input: $input) {
      id
    }
  }
`

// SCHEDULES

export const UPDATE_SCHEDULE_BY_ID = gql`
  ${GROUP_SCHEDULE}
  mutation updateScheduleById($id: String! $input: UpdateScheduleInput! ) {
    updateScheduleById(id: $id input: $input) {
      ...groupSchedule
    }
  }
`

// DEVICES

export const ATTACH_DEVICE = gql`
  ${DEVICE_BASE}
  mutation attachDevice($input: AttachDeviceInput!) {
    attachDevice(input: $input) {
      ...deviceBase
    }
  }
`

export const UPDATE_DEVICE_BY_ID = gql`
  ${DEVICE_BASE}
  mutation updateDeviceById($id: String! $input: UpdateDeviceInput!) {
    updateDeviceById(id: $id input: $input) {
      ...deviceBase
    }
  }
`

export const DELETE_DEVICE_BY_ID = gql`
  mutation deleteDeviceById($id: String!) {
    deleteDeviceById(id: $id)
  }
`

export const DELETE_DEVICES_BY_IDS = gql`
  mutation deleteDevicesByIds($ids: [String!]!) {
    deleteDevicesByIds(ids: $ids)
  }
`

export const MOVE_DEVICE_BY_ID = gql`
  ${DEVICE_BASE}
  mutation moveDeviceById($id: String!, $input: MoveDeviceInput!) {
    moveDeviceById(id: $id, input: $input) {
      ...deviceBase
    }
  }
`

export const MOVE_DEVICES_BY_IDS = gql`
  ${DEVICE_BASE}
  mutation moveDevicesByIds($ids: [String!]!, $input: MoveDeviceInput!) {
    moveDevicesByIds(ids: $ids, input: $input) {
      ...deviceBase
    }
  }
`

export const INITIALIZE_DEVICE_PREVIEW = gql`
  mutation initializeDevicePreview($deviceId: String!) {
    initializeDevicePreview(deviceId: $deviceId) {
      webrtcUrl
      server
      streamKey
      renewPreviewTimeoutSeconds
    }
  }
`

export const SEND_DEVICE_PREVIEW_WEBRTC_OFFER = gql`
  mutation sendDevicePreviewWebrtcOffer($deviceId: String!, $offerSdp: String!) {
    sendDevicePreviewWebrtcOffer(
      deviceId: $deviceId
      offerSdp: $offerSdp
    ) {
      eTag
      answerSdp
    }
  }
`

export const SEND_DEVICE_PREVIEW_LOCAL_CANDIDATES = gql`
  mutation sendDevicePreviewWebrtcLocalCandidates($deviceId: String!, $sdpFragment: String!, $eTag: String!) {
    sendDevicePreviewWebrtcLocalCandidates(
      deviceId: $deviceId
      sdpFragment: $sdpFragment
      eTag: $eTag
    )
  }
`

// PLAYLISTS

export const CREATE_PLAYLIST = gql`
  ${PLAYLIST_BASE}
  mutation createPlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      ...playlistBase
    }
  }
`

export const UPDATE_PLAYLIST_BY_ID = gql`
  ${UPDATED_PLAYLIST}
  mutation updatePlaylist($id: String!  $playlistVersion: Int $input: UpdatePlaylistInput! $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    updatePlaylist(playlistId: $id input: $input playlistVersion:$playlistVersion changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

export const EXPORT_SLIDES_TO_PLAYLISTS = gql`
  mutation appendPlaylistZones($slides: [CreatePlaylistLayoutZoneSlideInput!]! $references: [PlaylistZoneReference!]!) {
    appendPlaylistZones(slides: $slides references: $references)  {
      id
    }
  }
`

export const COPY_PLAYLIST_SLIDES = gql`
  mutation copyPlaylistSlidesToMultipleReferences($slideIds: [String!]! $toReferences: [PlaylistZoneReference!]! $fromPlaylistId: String! $fromPlaylistVersion: Int $fromZoneId: String!) {
    copyPlaylistSlidesToMultipleReferences(slideIds: $slideIds toReferences: $toReferences fromPlaylistId: $fromPlaylistId fromPlaylistVersion: $fromPlaylistVersion fromZoneId: $fromZoneId)  {
      id
    }
  }
`


export const DELETE_PLAYLIST_BY_ID = gql`
  mutation deletePlaylistById($id: String! $force: Boolean) {
    deletePlaylistById(id: $id force: $force)
  }
`

export const COPY_PLAYLIST_BY_ID = gql`
  ${PLAYLIST_BASE}
  mutation copyPlaylist($id: String! $input: CopyPlaylistInput! $playlistVersion: Int) {
    copyPlaylist(playlistId: $id input: $input playlistVersion:$playlistVersion) {
      ...playlistBase
    }
  }
`

export const MOVE_PLAYLIST_BY_ID = gql`
  mutation movePlaylistById($id: String! $input: MovePlaylistInput!) {
    movePlaylistById(id: $id input: $input) {
      id
      groupId
    }
  }
`

export const ADD_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation addPlaylistSlide($playlistId: String! $zoneId: String! $input: CreatePlaylistLayoutZoneSlideInput! $afterSlideId: String) {
    addPlaylistSlide(playlistId: $playlistId zoneId: $zoneId input: $input afterSlideId: $afterSlideId) {
      ...updatedPlaylist
    }
  }
`

export const UPDATE_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation updatePlaylistSlide($playlistId: String! $zoneId: String! $slideId: String! $input: UpdateSlideInput! $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    updatePlaylistSlide(playlistId: $playlistId zoneId: $zoneId slideId: $slideId input: $input changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

export const MOVE_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation movePlaylistSlide($playlistId: String! $zoneId: String! $toZoneId: String! $slideId: String! $afterSlideId: String $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    movePlaylistSlide(playlistId: $playlistId zoneId: $zoneId toZoneId: $toZoneId slideId: $slideId afterSlideId: $afterSlideId  changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

export const DELETE_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation deletePlaylistSlide($playlistId: String! $zoneId: String! $slideId: String! $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    deletePlaylistSlide(playlistId: $playlistId zoneId: $zoneId slideId: $slideId changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

export const PUBLISH_PLAYLIST_VERSION = gql`
  mutation publishPlaylistVersion($playlistId: String! $playlistVersion: Int!) {
    publishPlaylistVersion(playlistId: $playlistId playlistVersion: $playlistVersion)
  }
`

export const PAUSE_PLAYLIST = gql`
  mutation pausePlaylist($playlistId: String!) {
    pausePlaylist(playlistId: $playlistId) {
      id
    }
  }
`

export const UNPAUSE_PLAYLIST = gql`
  mutation unpausePlaylist($playlistId: String!) {
    unpausePlaylist(playlistId: $playlistId) {
      id
    }
  }
`

export const PAUSE_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation pausePlaylistSlide($playlistId: String! $zoneId: String $slideId: String! $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    pausePlaylistSlide(plsylistId: $playlistId zoneId: $zoneId slideId: $slideId changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

export const UNPAUSE_PLAYLIST_SLIDE = gql`
  ${UPDATED_PLAYLIST}
  mutation pausePlaylistSlide($playlistId: String! $zoneId: String $slideId: String! $changeLogs: [CreatePlaylistChangeLogWrapperInput!]) {
    pausePlaylistSlide(plsylistId: $playlistId zoneId: $zoneId slideId: $slideId changeLogs: $changeLogs) {
      ...updatedPlaylist
    }
  }
`

// SOCIAL

export const LINK_SOCIAL_ACCOUNT_TWITTER = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNT_TWITTER_BASE}
  mutation linkSocialAccountTwitter($input: LinkSocialAccountTwitterInput!) {
    linkSocialAccountTwitter(input: $input) {
      ...socialAccountBase
      ...socialAccountTwitterBase
    }
  }
`

export const LINK_SOCIAL_ACCOUNT_INSTAGRAM = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNT_INSTAGRAM_BASE}
  mutation linkSocialAccountsInstagram($input: LinkSocialAccountInstagramInput!) {
    linkSocialAccountsInstagram(input: $input) {
      ...socialAccountBase
      ...socialAccountInstagramBase
    }
  }
`

export const LINK_SOCIAL_ACCOUNT_GOOGLE = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNT_GOOGLE_BASE}
  mutation linkSocialAccountGoogle($input: LinkSocialAccountGoogleInput!) {
    linkSocialAccountGoogle(input: $input) {
      ...socialAccountBase
      ...socialAccountGoogleBase
    }
  }
`

export const LINK_SOCIAL_ACCOUNT_MICROSOFT = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNT_MICROSOFT_BASE}
  mutation linkSocialAccountMicrosoft($input: LinkSocialAccountMicrosoftInput!) {
    linkSocialAccountMicrosoft(input: $input) {
      ...socialAccountBase
      ...socialAccountMicrosoftBase
    }
  }
`

export const LINK_SOCIAL_ACCOUNT_DROPBOX = gql`
  ${SOCIAL_ACCOUNT_BASE}
  ${SOCIAL_ACCOUNT_DROPBOX_BASE}
  mutation linkSocialAccountDropbox($input: LinkSocialAccountDropboxInput!) {
    linkSocialAccountDropbox(input: $input) {
      ...socialAccountBase
      ...socialAccountDropboxBase
    }
  }
`

export const LINK_SOCIAL_ACCOUNT_CANVA = gql`
  ${SOCIAL_ACCOUNT_BASE}
  mutation linkSocialAccountCanva($input: LinkSocialAccountCanvaInput! $nonce: String!) {
    linkSocialAccountCanva(input: $input, nonce: $nonce) {
      ...socialAccountBase
    }
  }
`

export const UNLINK_SOCIAL_ACCOUNT = gql`
  mutation unlinkSocialAccount($force: Boolean $id: String!) {
    unlinkSocialAccount(force: $force, id: $id) 
  }
`

// SUBSCRIPTION


export const PREPARE_SETUP_INTENT = gql`
  mutation prepareSetupIntent {
    prepareSetupIntent {
      id
      client_secret
      status      
    }
  }
`

export const DETACH_PAYMENT_METHOD = gql`
  mutation detachPaymentMethod {
    detachPaymentMethod 
  }
`

export const UPDATE_WORKSPACE_SUBSCRIPTION = gql`
  ${SUBSCRIPTION}
  mutation updateWorkspaceSubscription ($input: UpdateWorkspaceSubscriptionInput!) {
    updateWorkspaceSubscription(input: $input) {
      ...subscriptionInfo
    }
  }
`

export const UPDATE_WORKSPACE_SUBSCRIPTION_BY_SIGNATURE = gql`
  ${SUBSCRIPTION}
  mutation updateWorkspaceSubscriptionBySignature ($signature: String!) {
    updateWorkspaceSubscriptionBySignature(signature: $signature) {
      ...subscriptionInfo
    }
  }
`

export const PREVIEW_WORKSPACE_SUBSCRIPTION_UPDATE = gql`
  mutation previewWorkspaceSubscriptionUpdate ($input: UpdateWorkspaceSubscriptionInput!) {
    previewWorkspaceSubscriptionUpdate(input: $input) {
      amount_due
      ending_balance
      starting_balance
      subtotal 
      total
      proration_date
      billing_cycle_anchor
    }
  }
`

export const PREVIEW_AND_SIGN_WORKSPACE_SUBSCRIPTION_UPDATE = gql`
  mutation previewWorkspaceSubscriptionUpdate ($input: UpdateWorkspaceSubscriptionInput!) {
    previewWorkspaceSubscriptionUpdate(input: $input) {
      preview {
        amount_due
        ending_balance
        starting_balance
        subtotal
        total
        proration_date
      }
      signature
    }
  }
`

export const REDEEM_PROMOTION_CODE = gql`
  ${PROMO_CODE_INFO}
  mutation redeemPromotionCode ($code: String!) {
    redeemPromotionCode(code: $code) {
      ...promoCodeInfo
    }
  }
`

export const UPDATE_WORKSPACE_CUSTOMER = gql`
  ${WORKSPACE_CUSTOMER}
  mutation updateWorkspaceCustomer ($input: UpdateWorkspaceCustomerInput!) {
    updateWorkspaceCustomer(input: $input) {
      ...customer
    }
  }
`

// NOTIFICATIONS

export const MARK_NOTIFICATIONS_AS_SEEN = gql`
  mutation markNotificationsAsSeen($notificationIds: [String!]!) {
    markNotificationsAsSeen(notificationIds: $notificationIds)
  }
`
export const MARK_ALL_NOTIFICATIONS_AS_SEEN = gql`
  mutation markAllNotificationsAsSeen {
    markAllNotificationsAsSeen
  }
`

// TAGS

export const SET_DEVICE_CUSTOM_TAGS_BY_DEVICE_ID = gql`
  mutation setDeviceCustomTagsByDeviceId($id: String! $input: SetCustomTagsInput!) {
    setDeviceCustomTagsByDeviceId(id: $id input: $input)
  }
`

// WEBHOOKS

export const CREATE_WEBHOOK = gql`
  ${WEBHOOK_BASE}
  mutation createWebhook($input: CreateWebhookInput!){
    createWebhook(input: $input) {
      ...webhookBase
    }
  }
`

export const UPDATE_WEBHOOK_BY_ID = gql`
  ${WEBHOOK_BASE}
  mutation updateWebhookById($id: String! $input: UpdateWebhookInput!){
    updateWebhookById(input: $input) {
      ...webhookBase
    }
  }
`

export const DELETE_WEBHOOK_BY_ID = gql`
  ${WEBHOOK_BASE}
  mutation deleteWebhookById($id: String!){
    deleteWebhookById(input: $input) {
      ...webhookBase
    }
  }
`

// API KEYS

export const CREATE_EXTERNAL_API_KEY = gql`
  ${API_KEYS_BASE}
  mutation createExternalApiKey($input: CreateExternalApiKeyInput!){
    createExternalApiKey(input: $input) {
      ...apiKeyBase
    }
  }
`

export const UPDATE_EXTERNAL_API_KEY = gql`
  ${API_KEYS_BASE}
  mutation updateExternalApiKeyById($id:String! $input: UpdateExternalApiKeyInput!){
    updateExternalApiKeyById(id:$id input: $input) {
      ...apiKeyBase
    }
  }
`

export const DELETE_EXTERNAL_API_KEY = gql`
  mutation deleteExternalApiKeyById($id:String!){
    deleteExternalApiKeyById(id:$id)
  }
`

export const RESOLVE_EXTERNAL_API_KEY_VALUE = gql`
  mutation resolveExternalApiKeyValue($externalApiKeyId:String!){
    resolveExternalApiKeyValue(externalApiKeyId:$externalApiKeyId)
  }
`

//PLAYLIST SOUNDTRACK

export const UPLOAD_PLAYLIST_SOUNDTRACK = gql`
  ${BASIC_MEDIA_BASE}
  mutation uploadPlaylistStaticMediaAudioByReference ($file:Upload! $playlistId:String! $playlistVersion:Int) {
    uploadPlaylistStaticMediaAudioByReference(file:$file playlistId:$playlistId playlistVersion:$playlistVersion) {
      ...basicMediaBase
    }
  }
`

export const DELETE_PLAYLIST_SOUNDTRACK = gql`
  mutation deletePlaylistMediaAudioByReference ($playlistId:String! $playlistVersion:Int) {
    deletePlaylistMediaAudioByReference(playlistId:$playlistId playlistVersion:$playlistVersion)
  }
`

export const SET_PLAYLIST_SOUNDTRACK_URL = gql`
  mutation setPlaylistExternalUrlMediaAudioByReference ($name:String $url:String! $playlistId:String! $playlistVersion:Int) {
    setPlaylistExternalUrlMediaAudioByReference(name:$name url:$url playlistId:$playlistId playlistVersion:$playlistVersion)
  }
`
